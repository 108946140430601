<template>
  <div>
    <statistics-filter
      horizontal
      class="mb-5"
      @load-data="(data) => (chartData = data)"
      @query="queryUpdate"
    />
    <transition name="fade" mode="out-in">
      <div class="statistics">
        <section class="mb-5">
          <stats-card-bar
            key="global"
            show-rate
            show-days
            :chart-data="globalData"
            type="bar-chart"
            :statistics="globalData"
            legend
            hide-label
          ></stats-card-bar>
        </section>
        <section class="mb-5">
          <equipments-type-data :query="query" />
        </section>
      </div>
    </transition>
  </div>
</template>

<script>
import StatisticsFilter from './StatisticsFilter'
import StatsCardBar from '@/components/cards/StatsCardBar.vue'
import EquipmentsTypeData from './EquipmentsTypeData.vue'

export default {
  components: {
    StatisticsFilter,
    StatsCardBar,
    EquipmentsTypeData,
  },
  data: () => ({
    chartData: {},
    statistics: null,
    query: '',
  }),
  computed: {
    globalData() {
      return this.chartData
    },
  },
  methods: {
    queryUpdate(query) {
      this.query = query
    },
    getFormattedDataAttributes(value) {
      return this.$keysToCamel(this.chartData[value] || {})
    },
  },
}
</script>

<style lang="scss" scoped>
.statistics {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 1rem;
  section:nth-child(1) {
    grid-column: span 2 / span 2;
  }
  section:nth-child(2) {
    grid-column: span 1 / span 1;
  }
  @media only screen and (max-width: 1200px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}
.domain__title {
  font-size: 2rem;
  margin-bottom: 1rem;
  text-transform: uppercase;
}

.stats__column,
.data__content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.stats-domain__data {
  flex: 1;

  .card,
  .card-content {
    height: 100%;
  }

  .data__content {
    > div:first-child {
      flex: 1;
    }
  }
}

.stats-global__data {
  h3 {
    text-align: center;
    font-weight: bold;
    margin-bottom: 1.5rem;
  }

  .domain-card {
    flex: 1;
  }
}
</style>
