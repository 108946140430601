<template>
  <div class="password">
    <div class="card">
      <div class="card-content">
        <h4 class="header-text-message">Saisissez un nouveau mot de passe</h4>

        <b-message
          auto-close
          :active.sync="isMessageActive"
          type="is-warning"
          :duration="5000"
        >
          {{ errorMessage }}
        </b-message>

        <form class="password-form" @submit.prevent="onReset">
          <b-field label="Email" label-for="email">
            <b-input id="email" type="email" v-model="form.email" required>
            </b-input>
          </b-field>
          <password-check ref="password-check" />

          <b-button class="block mt-4" native-type="submit" :loading="pending">
            Modifier
          </b-button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import PasswordCheck from '@/components/PasswordCheck.vue'
export default {
  components: { PasswordCheck },
  props: ['token'],
  data: () => ({
    form: {
      email: null,
      password: null,
      password_confirmation: null,
    },
    errorMessage: null,
    isMessageActive: false,
    pending: false,
  }),
  created() {
    this.form.email = this.$route.query.email
  },
  methods: {
    async onReset() {
      this.pending = true

      let passwordCheck = this.$refs['password-check']

      let form = this.form
      form.password = passwordCheck.form.password
      form.password_confirmation = passwordCheck.form.password_confirmation

      try {
        await this.$axios.post('/auth/reset-password', {
          token: this.token,
          ...form,
        })

        this.$buefy.toast.open({
          message: 'Mot de passe réinitialisé avec succès',
          type: 'is-success',
          position: 'is-bottom',
          duration: 3000,
        })

        /**
         * Redirection vers page de login
         */
        this.$router.push({ name: 'Login' })
      } catch (e) {
        if (!e.response) {
          this.errorMessage = e.message
        } else if (e.response.data.errors) {
          if (e.response.data.errors.email) {
            this.errorMessage = e.response.data.errors.email[0]
          } else if (e.response.data.errors.password) {
            this.errorMessage = e.response.data.errors.password[0]
          }
        }
        this.isMessageActive = true
      } finally {
        this.pending = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.password {
  margin-top: 3rem;

  @include tablet {
    display: flex;
    justify-content: center;
  }
}

.card {
  padding: 1.5rem;

  @include tablet {
    width: 600px;
    padding: 3rem;
  }
}

/deep/ .label {
  font-weight: normal;

  &:not(:last-child) {
    margin-bottom: 0;
  }
}

/deep/ .field:not(:last-child) {
  margin-bottom: 2rem;
}

/deep/ .input {
  height: 3.5rem !important;
}
</style>
