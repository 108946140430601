<template>
  <div>
    <div class="equipments__add">
      <b-button size="is-small" @click="openModal()">
        Gérer la résiliation
      </b-button>
      <div v-if="client.invoice_url">
        <a
          :href="client.invoice_url"
          target="_blank"
          rel="noopener noreferrer"
          class="mt-1 is-block"
          >Voir la lettre de résiliation</a
        >
        <p class="mt-2" v-if="client.invoice_entry_date">Date de réception : <span style="font-weight:bold">{{ $formatDate(client.invoice_entry_date, 'dd/MM/yyyy hh:mm') }}</span></p>
        <p v-if="client.invoice_entry_date">Date effective : <span style="font-weight:bold">{{ $formatDate(client.invoice_effective_date, 'dd/MM/yyyy hh:mm') }}</span></p>
      </div>
    </div>
    <b-modal :active.sync="isModalActive" has-modal-card>
      <div class="modal-card">
        <form model="clients" class="modal-card-body" @submit.prevent="submit">
          <h2 style="font-weight:bold; text-align:center; font-size:1.1rem">Résilier le client</h2>
           <div class="mt-4">
            <b-field label="Date d'entrée" label-for="invoice_entry_date">
              <base-datepicker
                id="invoice_entry_date"
                icon="calendar-today"
                trap-focus
                v-model="form.invoice_entry_date"
                @input="period = null"
                :force-mobile="true"
                with-time
              ></base-datepicker>
            </b-field>
            <b-field label="Date effective" label-for="invoice_effective_date">
              <base-datepicker
                id="invoice_effective_date"
                icon="calendar-today"
                trap-focus
                v-model="form.invoice_effective_date"
                @input="period = null"
                :force-mobile="true"
                with-time
              ></base-datepicker>
            </b-field>
          </div>
          <div style="display:flex; align-items: center; gap: 10px; margin-bottom: 10px">
            <b-field
              class="file is-primary mt-3"
              :class="{ 'has-name': !!form.file }"
            >
              <b-upload v-model="form.file" class="file-label">
                <span class="file-cta">
                  <b-icon class="file-icon" icon="upload"></b-icon>
                  <span class="file-label">Téléverser</span>
                </span>
                <span class="file-name" v-if="form.file">
                  {{ form.file.name }}
                </span>
                <div v-if="form.file">
                  <span class="file-name" v-if="!form.file.name">
                    {{ filename }}
                  </span>
                </div>
              </b-upload>
            </b-field>
            <b-button icon-left="trash-can-outline" v-if="client.invoice_url" size="" @click="isDeleteInvoiceModalActive = true">
            </b-button>
          </div>
          <div class="is-flex">
            <div class="ml-auto" >
              <b-button type="is-primary" native-type="submit" :loading="saving">
                Envoyer
              </b-button>
               <b-button class="ml-2" @click="isModalActive = false">
               Annuler
            </b-button>
            </div>
          </div>
        </form>
      </div>
    </b-modal>
    <b-modal :active.sync="isDeleteInvoiceModalActive" has-modal-card>
      <div class="modal-card">
        <form model="clients" class="modal-card-body" @submit.prevent="submit">
          <div class="card-header">
            <div class="card-header_title">
              <h2 style="font-weight:bold">Supprimer la lettre de résiliation</h2>
            </div>
          </div>
           <div class="is-flex mt-5" style="justify-content: end;">
           <b-button type="is-primary" @click="deleteInvoice(form.file)">
            Confirmer
            </b-button>
            <br>
            <b-button class="ml-2" @click="isDeleteInvoiceModalActive = false">
            Annuler
            </b-button>
          </div>
        </form>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
export default {
  data() {
    return {
      isModalActive: false,
      isDeleteInvoiceModalActive: false,
      filename: null,
      existedFile: null,
      form: {
        invoice_entry_date: null,
        invoice_effective_date: null,
        file: null,
      },
      saving: false,
    }
  },
  computed: {
    ...mapState({
      client: (state) => state.client,
    }),
  },
  methods: {
    openModal()
    {
      this.isModalActive = true
      
      this.form.invoice_entry_date = this.client.invoice_entry_date
      this.form.invoice_effective_date = this.client.invoice_effective_date

      this.form.file = this.client.invoice_file
    },
    async submit() {
      this.saving = true
      let toast = {
        message: 'Données enregistrées',
        type: 'is-success',
      }
      
      
      if(this.form.invoice_entry_date)
      {
      const entry_date = new Date(this.form.invoice_entry_date);

      // Extraire les différentes parties de la date
      const year = entry_date.getFullYear();
      const month = String(entry_date.getMonth() + 1).padStart(2, '0');
      const day = String(entry_date.getDate()).padStart(2, '0');
      const hours = String(entry_date.getHours()).padStart(2, '0');
      const minutes = String(entry_date.getMinutes()).padStart(2, '0');
      const seconds = String(entry_date.getSeconds()).padStart(2, '0');

      // Réassembler les parties de la date dans le format souhaité (YYYY-MM-DD HH:mm:ss)
      const entryDateFormatee = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      this.form.invoice_entry_date = entryDateFormatee;
      }

      if(this.form.invoice_effective_date)
      {
      const effective_date = new Date(this.form.invoice_effective_date);
      // Extraire les différentes parties de la date
      const year2 = effective_date.getFullYear();
      const month2 = String(effective_date.getMonth() + 1).padStart(2, '0');
      const day2 = String(effective_date.getDate()).padStart(2, '0');
      const hours2 = String(effective_date.getHours()).padStart(2, '0');
      const minutes2 = String(effective_date.getMinutes()).padStart(2, '0');
      const seconds2 = String(effective_date.getSeconds()).padStart(2, '0');

      // Réassembler les parties de la date dans le format souhaité (YYYY-MM-DD HH:mm:ss)
      const effectiveDateFormatee = `${year2}-${month2}-${day2} ${hours2}:${minutes2}:${seconds2}`;

      this.form.invoice_effective_date = effectiveDateFormatee;
      }

      let send = this.form
      let formData = new FormData()
      Object.entries(send).forEach(([key, value]) => {
        formData.append(key, value)
      })
      send = formData

      if(this.form.file)
        {
        try {
          let { data } = await this.$axios({
            method: 'post',
            url: `/api/clients/${this.$route.params.id}/cancel`,
            data: send,
          })
        } catch (error) {
          toast = {
            message: 'Erreur',
            type: 'is-failed',
          }
        }
      }

      if(this.form.file)
      {
        this.$buefy.toast.open({
          duration: 5000,
          message: toast.message,
          position: 'is-bottom',
          type: toast.type,
        })
      }else{
        this.$buefy.toast.open({
          duration: 5000,
          message: "Données enregistrées",
          position: 'is-bottom',
          type: "is-success",
        })
      }
      this.saving = false
      this.$emit('refresh')
      this.isModalActive = false
    },

    async deleteInvoice(media)
    {
      let send = this.form
      let formData = new FormData()
      Object.entries(send).forEach(([key, value]) => {
        formData.append(key, value)
      })
      this.form.file = media
      send = formData

      try {
        let { data } = await this.$axios({
          method: 'post',
          url: `/api/clients/${this.$route.params.id}/delete/invoice`,
          data: send,
        })
      } catch (error) {
        toast = {
          message: 'Erreur',
          type: 'is-failed',
        }
      }
      this.saving = false
      this.$emit('refresh')
      this.isDeleteInvoiceModalActive = false
      this.isModalActive = false
    }
  },
}
</script>

<style lang="css" scoped>
.equipments__add {
  padding: 1rem;
  button {
    margin-left: auto;
  }
}
</style>
