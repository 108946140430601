<template>
  <b-autocomplete
    :id="id"
    :data="data"
    :placeholder="placeholder"
    :loading="isFetching"
    :value="value"
    @typing="getAsyncData"
    :field="field"
    @select="(model) => $emit('select', model)"
    @input="(val) => $emit('input', val)"
  >
    <template slot-scope="props">
      <strong>{{ props.option.make }}</strong> - {{ props.option.name }}
    </template>
  </b-autocomplete>
</template>

<script>
import debounce from 'lodash/debounce'
import qs from 'qs'

export default {
  props: {
    id: String,
    value: String,
    placeholder: {
      type: String,
      default: 'Rechercher un modèle de véhicule',
    },
    perPage: {
      type: Number,
      default: 20,
    },
    field: String,
  },
  data() {
    return {
      data: [],
      isFetching: false,
    }
  },
  methods: {
    getAsyncData: debounce(async function (q) {
      if (!q.length) {
        this.data = []
        return
      }

      this.isFetching = true

      let { data } = await this.$axios(
        `api/car-models?${qs.stringify({
          q,
        })}`
      )

      this.data = data

      this.isFetching = false
    }, 500),
  },
}
</script>
