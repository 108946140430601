<template>
    <div>
        <div
            v-if="equipment &&
            equipment.status !== 'ABANDONMENT' &&
            equipment.status !== 'CANCELED' &&
            equipment.status !== 'NEW' &&
            equipment.status !== 'QUOTATION_WAIT' &&
            equipment.status !== 'INSTALLATION_ABANDON'
            "
            class="card p-5"
        >
            <div class="card-header">
                <div class="card-header_title">Installation</div>
                <div class="card-header_btn">
                    <b-button v-if="canEdit" icon-left="pencil-outline" @click="edition = !edition"></b-button>
                </div>
            </div>
            <base-model-form
                v-if="(equipment.status === 'TO_INSTALL' || edition) && canEdit"
                model="clients"
                v-model="form"
                model-name="Client"
                :custom-url="`/api/clients/${client.id}/equipment/${$route.query.equipment}`"
                custom-method="patch"
                :saving.sync="saving"
                :replace="replace"
                updated
            >
                <div class="mt-4">
                    <base-field label="Technicien" name="technician">
                        <b-input id="technician" v-model="form.technician"></b-input>
                    </base-field>
                    <b-field label="Prévu le" label-for="planned_at">
                        <base-datepicker
                            id="planned_at"
                            icon="calendar-today"
                            trap-focus
                            v-model="form.planned_at"
                            @input="period = null"
                            with-time
                            :force-mobile="true"
                        ></base-datepicker>
                    </b-field>
                    <base-field label="N° TR" name="tr_number">
                        <b-input
                            id="tr_number"
                            v-model="form.tr_number"
                            type="text"
                        ></b-input>
                    </base-field>
                </div>
                <div class="is-flex mt-5" v-if="this.user.is_super_admin || this.user.is_admin || this.user.is_user">
                    <b-button
                        tag="router-link"
                        class="mx-auto"
                        :to="{ name: 'ClientList' }"
                    >
                        Annuler
                    </b-button>
                    <b-button
                        native-type="submit"
                        type="is-primary"
                        :loading="saving"
                        class="mx-auto"
                    >
                        Valider
                    </b-button>
                </div>
            </base-model-form>
            <div v-else class="mt-3">
                <div>
                    <div v-if="!canEdit && !equipment.technician && equipment.planned_at==undefined && equipment.tr_number==undefined">
                        Etape en cours...
                    </div>
                    <div v-if="canEdit || equipment.technician">
                        Technicien : {{ equipment.technician }}
                    </div>
                    <div class="mt-2" v-if="canEdit || equipment.planned_at!=undefined">
                        Installé le
                        {{ $formatDate(equipment.planned_at, 'dd/MM/yyyy hh:mm') }}
                    </div>
                    <div class="mt-2" v-if="canEdit || equipment.tr_number!=undefined">
                        N° TR : {{ equipment.tr_number }}
                    </div>
                    <div v-if="canEdit">
                        <div class="mt-3">
                            <b-button
                                :loading="installLoading"
                                :disabled="equipment.status !== 'INSTALL_WIP'"
                                @click="setInstalled"
                            >Valider l'installation
                            </b-button>
                        </div>
                        <div class="mt-4">
                            <b-button
                                :loading="activeLoading"
                                :disabled="equipment.status !== 'INSTALLED'"
                                @click="setActive"
                            >
                                Valider l'activation
                            </b-button>
                        </div>
                        <div class="mt-4">
                            <b-button
                                :loading="installLoading"
                                @click="isModalCancelInstallationActive = true"
                            >Abandonner l'installation
                            </b-button>
                        </div>
                    </div>
                </div>
            </div>
            <b-modal :active.sync="isModalCancelInstallationActive" has-modal-card>
                <div class="modal-card">
                    <form model="clients" class="modal-card-body" @submit.prevent="submit">
                        <h2 class="text-center font-bold !text-xl">Abandon Installation</h2>
                        <b-field
                            class="mt-4"
                            label="Commentaire"
                            name="commentNoInstallation"
                        >
                            <b-input
                                id="commentInstallation"
                                v-model="form.commentNoInstallation"
                                expanded
                                type="textarea"
                            ></b-input>
                        </b-field>
                        <div class="is-flex" style="justify-content: end; gap: 10px">
                            <div class="mt-2">
                                <b-button
                                    type="is-primary"
                                    :loading="installLoading"
                                    @click="setAbandonment"
                                >Valider
                                </b-button>
                            </div>
                            <div class="mt-2">
                                <b-button
                                    :loading="installLoading"
                                    @click="isModalCancelInstallationActive = false"
                                >
                                    Annuler
                                </b-button>
                            </div>
                        </div>
                    </form>
                </div>
            </b-modal>
        </div>
        <div v-if="equipment.status === 'INSTALLATION_ABANDON' && equipment.installation_canceled_reason" class="card p-5">
            <div class="card-header">
                <div class="card-header_title">
                    Motif d'abandon
                </div>
            </div>
            <div class="text-center mt-5">
                {{equipment.installation_canceled_reason}}
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    data() {
        return {
            isModalCancelInstallationActive: false,
            isModalAddEquipmentActive: false,
            form: {
                status: this.$workflow.INSTALL_WIP,
                planned_at: null,
                tr_number: null,
                technician: null,
            },
            formFake: {
                status: this.$workflow.INSTALL_WIP,
                planned_at: '03/05/2022 08:00',
                tr_number: '12345',
                technician: 'John Doe',
            },
            saving: false,
            edition: false,
            installLoading: false,
            activeLoading: false,
        }
    },
    computed: {
        ...mapState({
            user: (state) => state.auth.user,
            client: (state) => state.client,
            equipment: (state) => state.equipment,
        }),
        canEdit() {
            return this.user.is_super_admin || this.user.is_admin || this.user.is_user
        },
    },
    mounted() {
        this.refresh()
    },
    watch: {
        equipment(newValue) {
            this.refresh()
        },
    },
    methods: {
        refresh() {
            if (this.equipment) {
                this.form.planned_at = this.equipment.planned_at
                this.form.tr_number = this.equipment.tr_number
                this.form.technician = this.equipment.technician
            } else {
                this.form.planned_at = null
                this.form.tr_number = null
                this.form.technician = null
                this.form.commentNoInstallation = null
            }
        },
        replace() {
            this.$emit('refresh')
            this.edition = false
        },
        async setInstalled() {
            this.installLoading = true
            let { data } = await this.$axios({
                method: 'patch',
                url: `/api/clients/${this.client.id}/equipment/${this.$route.query.equipment}`,
                data: {
                    status: this.$workflow.INSTALLED,
                },
            })
            this.installLoading = false
            this.$emit('refresh')
        },
        async setActive() {
            this.activeLoading = true
            let { data } = await this.$axios({
                method: 'patch',
                url: `/api/clients/${this.client.id}/equipment/${this.$route.query.equipment}`,
                data: {
                    status: this.$workflow.ACTIVE,
                },
            })
            this.activeLoading = false
            this.$emit('refresh')
        },
        async setAbandonment() {
            this.installLoading = true
            let { data } = await this.$axios({
                method: 'patch',
                url: `/api/clients/${this.client.id}/equipment/${this.$route.query.equipment}`,
                data: {
                    status: this.$workflow.INSTALLATION_ABANDON,
                    comment: this.form.commentNoInstallation,
                },
            })
            this.installLoading = false
            this.$emit('refresh')
        },
    },
}
</script>
