<template>
  <b-button
    title="Renvoyer le mail de création de compte"
    @click="onClick"
    icon-left="email-outline"
    :loading="loading"
  >
  </b-button>
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    loading: false,
  }),
  methods: {
    async onClick() {
      this.loading = true

      try {
        await this.$axios.post(`/api/users/${this.user.id}/resend`)
      } finally {
        this.loading = false
      }

      this.loading = false

      this.$buefy.toast.open({
        message: `Email de réinitialisation de mot de passe envoyé à ${this.user.name} !`,
        type: 'is-success',
        position: 'is-bottom',
        duration: 3000,
      })
    },
  },
}
</script>
