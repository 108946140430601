<template>
  <b-field class="file" :class="{ 'has-name': !!file }" v-if="!multiple">
    <b-upload
      :id="id"
      v-model="file"
      @input="onNewFile"
      :accept="accept"
      :expanded="expanded"
    >
      <a class="button is-fullwidth">
        <b-icon icon="upload"></b-icon>
        <span>Parcourir</span>
      </a>
    </b-upload>
    <span class="file-name" v-if="file">
      {{ file.name }}
    </span>
  </b-field>
  <section v-else>
    <b-field class="mb-3">
      <b-upload
        :id="id"
        v-model="dropFiles"
        @input="onNewFiles"
        :accept="accept"
        :expanded="expanded"
        :multiple="multiple"
        :drag-drop="dragDrop"
      >
        <section class="section" v-if="dragDrop">
          <div class="content has-text-centered">
            <p>
              <b-icon icon="upload" size="is-large"></b-icon>
            </p>
            <p>Glisser-déposer vos fichiers ici ou cliquer pour sélectionner</p>
          </div>
        </section>
        <a class="button is-fullwidth" v-else>
          <b-icon icon="upload"></b-icon>
          <span>Parcourir</span>
        </a>
      </b-upload>
    </b-field>
    <div class="tags">
      <span
        v-for="(file, index) in dropFiles"
        :key="index"
        class="tag is-primary"
      >
        {{ file.name }}
        <button
          class="delete is-small"
          type="button"
          @click="deleteDropFile(index)"
        ></button>
      </span>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    id: String,
    accept: String,
    expanded: Boolean,
    multiple: Boolean,
    dragDrop: Boolean,
  },
  data: () => ({
    file: null,
    dropFiles: [],
    files: [],
  }),
  methods: {
    async onNewFile(file) {
      this.onNewFiles([file])
    },
    async onNewFiles(files) {
      this.files = []

      files.forEach((f) => {
        let reader = new FileReader()

        reader.onload = (e) => {
          if (reader.readyState == FileReader.DONE) {
            let file = {
              base64: e.target.result,
              file_name: f.name,
              type: f.type,
            }
            this.files.push(file)

            this.$emit('input', this.multiple ? this.files : file)
          }
        }

        reader.readAsDataURL(f)
      })
    },
    deleteDropFile(index) {
      this.files.splice(index, 1)
      this.dropFiles.splice(index, 1)

      this.$emit('input', this.multiple ? this.files : null)
    },
  },
}
</script>
