<template>
  <component
    :is="withTime ? 'b-datetimepicker' : 'b-datepicker'"
    v-model="selected"
    :placeholder="placeholder"
    :icon="icon"
    :icon-right="selected ? 'close-circle' : ''"
    icon-right-clickable
    @icon-right-click="clearDateTime"
    locale="fr-FR"
    :first-day-of-week="1"
    :position="position"
    :datepicker="{ showWeekNumber: true }"
    :timepicker="{ enableSeconds: false, hourFormat: '24' }"
    :size="size"
    append-to-body
    @input="onChange"
    :class="[forceMobile ? 'force-mobile' : '']"
  >
    <template #left v-if="withTime">
      <b-button
        label="Maintenant"
        type="is-primary"
        icon-left="clock"
        @click="now"
      />
    </template>

    <template #right>
      <b-button
        type="is-danger"
        icon-left="close"
        class="btn-fermer"
        @click="closeDatepicker"
      />
    </template>
  </component>
</template>

<script>
export default {
  props: {
    value: [String, Date, Array],
    // id: String,
    icon: String,
    placeholder: String,
    position: {
      type: String,
      default: 'is-top-right',
    },
    size: String,
    // editable: Boolean,
    // range: Boolean,
    withTime: Boolean,
    forceMobile: Boolean, // Force l'affichage comme sur mobile, en plein écran et position fixed. Utile si risque d'affichage partiel car embarqué dans un composant avec overflow=hidden. Ticket #9372
  },
  data() {
    return {
      selected: null,
    }
  },
  mounted() {
    if (this.value) {
      this.selected = new Date(this.value)
    }
  },
  watch: {
    value(newValue) {
      this.selected = new Date(newValue)
    },
  },
  methods: {
    clearDateTime() {
      this.selected = null
      // this.onChange()
    },
    now() {
      this.selected = new Date()
      this.onChange()
    },
    onChange() {
      this.$emit('input', this.selected)
    },
    closeDatepicker() {
      const x = 100; // Coordonnée x où vous souhaitez simuler le clic
      const y = 200; // Coordonnée y où vous souhaitez simuler le clic

      const event = new MouseEvent('click', {
        clientX: x,
        clientY: y,
        bubbles: true,
        cancelable: true
      });

      const element = document.elementFromPoint(x, y);
      if (element) {
        element.dispatchEvent(event);
      }
    }
  },
}
</script>
<style lang="scss">
.datepicker.force-mobile .dropdown {
  .background {
    display: block;
  }
  > .dropdown-menu {
    position: fixed !important;
    width: calc(100vw - 40px);
    max-width: 370px;
    //max-height: calc(100vh - 120px);
    top: 25% !important;
    left: 50% !important;
    bottom: auto !important;
    right: auto !important;
    transform: translate3d(-50%, -25%, 0);
    white-space: normal;
    overflow-y: auto;
    z-index: 50 !important;
    > .dropdown-content {
      > .dropdown-item, > .has-link a {
        padding: 1rem 1.5rem;
      }
    }
  }
  .datepicker-footer {
    .timepicker {
      .dropdown {
        > .dropdown-menu {
          position: static !important;
          top: 100%;
          right: auto;
          bottom: auto;
          left: 0;
          transform: none;
          width: auto;
          max-width: none;
          .dropdown-content {
            padding: 0;
            .dropdown-item {
              padding: 0;
            }
          }
        }
      }
    }
    .btn-fermer {
      position: absolute;
      top: -10px;
      right: 10px;
      padding: 0;
      background: transparent;
      color: black;
    }
  }
}
</style>
