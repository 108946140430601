<template>
  <div>
    <base-field horizontal label="Civilité*" :name="`${prefix}civility`">
      <b-radio-button
        v-for="(label, i) in ['Mr', 'Mme']"
        :key="i"
        v-model="form.civility"
        :native-value="label"
        type="is-success is-light is-outlined"
      >
        {{ label }}
      </b-radio-button>
    </base-field>
    <base-field horizontal label="Nom*" :name="`${prefix}last_name`">
      <b-input v-model="form.last_name" />
    </base-field>
    <base-field horizontal label="Prénom*" :name="`${prefix}first_name`">
      <b-input v-model="form.first_name" />
    </base-field>
    <base-field horizontal label="Téléphone*" :name="`${prefix}phone`">
      <b-input v-model="form.phone" v-mask="'0# ## ## ## ##'" />
    </base-field>
    <base-field
      horizontal
      label="Temps de trajet*"
      :name="`${prefix}travel_time`"
    >
      <b-radio-button
        v-for="(label, i) in ['<15min', '<30min', '<60min']"
        :key="i"
        v-model="form.travel_time"
        :native-value="label"
        type="is-success is-light is-outlined"
      >
        {{ label }}
      </b-radio-button>
    </base-field>
    <base-field
      horizontal
      label="Dispose des clés"
      :name="`${prefix}has_home_keys`"
    >
      <b-switch id="has-home-keys" v-model="form.has_home_keys"></b-switch>
    </base-field>
    <base-field
      horizontal
      label="Parent de l'utilisateur"
      :name="`${prefix}is_parent`"
    >
      <b-switch id="is-parent" v-model="form.is_parent"></b-switch>
    </base-field>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      required: true,
    },
    prefix: {
      type: String,
      default: '',
    },
  },
  computed: {
    form: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
}
</script>
