<template>
  <base-model-form
    model="users"
    v-model="form"
    model-name="Utilisateur"
    redirect="/users"
    :default="model"
    :saving.sync="saving"
  >
    <div class="card">
      <div class="card-content">
        <div class="columns">
          <div v-if="!user.is_manager" class="column">
            <base-field label="Rôle*" name="role">
              <b-select
                id="role"
                placeholder="Sélectionner un rôle"
                expanded
                v-model="form.role"
                required
              >
                <option
                  v-for="(role, rolekey) in roles"
                  :value="rolekey"
                  :key="rolekey"
                >
                  {{ role }}
                </option>
              </b-select>
            </base-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <base-field label="Nom*" name="last_name">
              <b-input
                id="last_name"
                v-model="form.last_name"
                required
              ></b-input>
            </base-field>
          </div>
          <div class="column">
            <base-field label="Prénom*" name="first_name">
              <b-input
                id="first_name"
                v-model="form.first_name"
                required
              ></b-input>
            </base-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <base-field label="Email*" name="email">
              <b-input
                id="email"
                v-model="form.email"
                type="email"
                required
              ></b-input>
            </base-field>
          </div>
          <div class="column">
            <base-field label="Mot de passe" name="password">
              <b-input
                id="password"
                v-model="form.password"
                type="password"
              ></b-input>
            </base-field>
          </div>
        </div>
      </div>
    </div>

    <div class="submit-button mt-4">
      <div class="double-button">
        <back-button />
        <b-button native-type="submit" type="is-secondary" :loading="saving">
          <span v-if="model">Mettre à jour</span>
          <span v-else>Ajouter l'utilisateur</span>
        </b-button>
      </div>
    </div>
  </base-model-form>
</template>

<script>
import { mapState } from 'vuex'
import BackButton from '@/components/base/buttons/BackButton.vue'

export default {
  components: { BackButton },
  props: ['model'],
  data: () => ({
    form: {
      first_name: null,
      last_name: null,
      email: null,
      role: null,
      password: null,
    },
    formFake: {
      first_name: 'first_name',
      last_name: 'last_name',
      email: 'email@mail.com',
      role: 'USER',
      password: 'password',
    },
    saving: false,
  }),
  mounted() {
    if (!this.$route.path.includes('edit')) {
      if (process.env.NODE_ENV === 'development') {
        this.form = this.formFake
      }
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      roles: (state) => state.enums.roles,
      services: (state) => state.enums.services,
    }),
  },
}
</script>
