<template>
  <div class="container is-fluid">
    <div class="columns">
      <div class="column">
        <h1>Configuration</h1>
        <equipment-types></equipment-types>
      </div>
      <div class="column">
        <div>
          <h1>Prix d'installations</h1>
          <prices></prices>
        </div>
        <div class="mt-4">
          <h1>Textes</h1>
          <texts></texts>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <div>
          <h1>Packs</h1>
          <packs></packs>
        </div>
      </div>
      <div class="column">
        <h1>Avantages</h1>
        <benefits></benefits>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import EquipmentTypes from '@/components/config/EquipmentTypes.vue'
import Texts from '@/components/config/Texts.vue'
import Packs from '@/components/config/Packs.vue'
import Benefits from '@/components/config/Benefits.vue'
import Prices from '@/components/config/Prices.vue'

export default {
  components: {
    EquipmentTypes,
    Texts,
    Packs,
    Benefits,
    Prices,
  },
  data() {
    return {}
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
}
</script>
