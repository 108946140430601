<script>
import Stats from '@/mixins/stats'
import { mapState } from 'vuex'
import DaysData from '@/components/statistics/DaysData.vue'
import DoughnutChart from '@/components/charts/DoughnutChart.vue'

export default {
  components: {
    DoughnutChart,
    DaysData,
  },
  mixins: [Stats],
  props: {
    showRate: Boolean,
    showDays: Boolean,
    chartData: {
      type: Object,
      default: () => {},
    },
    domain: String,
    label: {
      type: String,
      default: '',
    },
    statistics: {
      type: Object,
      default: () => {},
    },
    legend: {
      type: Boolean,
      default: false,
    },
    hideLabel: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      status: (state) => state.enums.statuses,
    }),
    getLabels() {
      if (this.statistics) {
        let labels = []
        this.data.forEach((el, key) => {
          labels.push({
            prop: el.value,
            label: this.statistics[key].label,
            color: this.statistics[key].color,
          })
        })
        return labels
      }
      let labels = Object.keys(this.status).filter((v) => v !== 'closed')
      return labels.map((prop) => {
        return {
          prop,
          label: this.status[prop],
          color: this.$statusColors[prop],
        }
      })
    },
  },
}
</script>

<template>
  <div class="card">
    <div class="card-content">
      <doughnut-chart
        class="chart__lead"
        :data="leadData"
        :labels="hideLabel ? undefined : getLabels"
        :options="{
          cutout: 100,
          plugins: { legend: { display: legend }, datalabels: { display: false } },
          responsive: true,
        }"
        :statistics="statistics"
      >
        <slot v-if="label">
          {{ $formatNumber(leadTotal) }}<br />
          <span v-if="label">{{ label }}</span>
        </slot>
      </doughnut-chart>
    </div>
  </div>
</template>
