<script>
import Stats from '@/mixins/stats'
import { mapState } from 'vuex'
import DaysData from '@/components/statistics/DaysData.vue'
import BarChart from '@/components/charts/BarChart.vue'

export default {
  components: {
    BarChart,
    DaysData,
  },
  mixins: [Stats],
  props: {
    showRate: Boolean,
    showDays: Boolean,
    chartData: {
      type: Object,
      default: () => {},
    },
    domain: String,
    label: {
      type: String,
      default: '',
    },
    statistics: {
      type: Object,
      default: () => {},
    },
    legend: {
      type: Boolean,
      default: false,
    },
    hideLabel: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      status: (state) => state.enums.statuses,
    }),
    getLabels() {
      if (this.statistics) {
        let labels = []
        this.data.forEach((el, key) => {
          labels.push({
            prop: el.value,
            label: this.statistics[key].label,
            color: this.statistics[key].color,
          })
        })
        return labels
      }
      let labels = Object.keys(this.status).filter((v) => v !== 'closed')
      return labels.map((prop) => {
        return {
          prop,
          label: this.status[prop],
          color: this.$statusColors[prop],
        }
      })
    },
  },
}
</script>

<template>
  <div class="card">
    <div class="card-content">
      <bar-chart
        :data="leadData"
        :labels="hideLabel ? undefined : getLabels"
        :options="{
          cutout: 180,
          plugins: { legend: { display: legend }, datalabels: { display: false } },
          responsive: true,
        }"
        :statistics="statistics"
      >
        <slot v-if="label">
          {{ $formatNumber(leadTotal) }}<br />
          <span v-if="label">{{ label }}</span>
        </slot>
      </bar-chart>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.card {
  /deep/ .chart__body {
    width: 100%;
  }
}
/*
.transform-rate {
  text-align: center;

  .rate__total {
    font-size: 4rem;
  }
}
.card {
  &.horizontal {
    .additional-data {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
    }

    .transform-rate {
      display: flex;
      flex-direction: column;
    }

    .rate__title {
      font-weight: normal;
      font-size: 1.2rem;
    }
  }

  &:not(.horizontal) {
    /deep/ .chart__body {
      @include desktop {
        width: 100%;
      }
    }

    /deep/ .chart__lead .centered-text {
      font-size: 1.8rem;
    }

    .rate__title {
      margin-right: 1rem;
    }
  }
}
*/
</style>
