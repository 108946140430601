<template>
  <b-select
    :id="id"
    :placeholder="placeholder"
    :loading="isFetching"
    :value="value"
    @input="(value) => $emit('input', value)"
    :size="size"
    :required="required"
    expanded
  >
    <option
      v-for="(item, value) in data"
      :value="enumData ? value : item[trackedBy]"
      :key="value"
    >
      <span v-if="enumData">
        {{ item }}
      </span>
      <span v-else>
        {{ text(item) }}
      </span>
    </option>
  </b-select>
</template>

<script>
import qs from 'qs'

export default {
  props: {
    id: String,
    value: {
      type: [Object, String, Number],
      default: () => {},
    },
    trackedBy: {
      type: String,
      default: 'id',
    },
    text: Function,
    model: [String, Object],
    placeholder: String,
    size: String,
    perPage: {
      type: Number,
      default: 30,
    },
    filter: {
      type: Object,
      default: () => {},
    },
    enumData: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      defualt: false,
    },
  },
  data() {
    return {
      data: [],
      isFetching: false,
    }
  },
  created() {
    if (this.enumData) {
      this.data = this.model
    } else {
      this.getAsyncData()
    }
  },
  methods: {
    async getAsyncData() {
      this.isFetching = true

      let { data } = await this.$axios.get(
        `/api/${this.model}?${qs.stringify({
          perPage: this.perPage,
          page: 1,
          filter: this.filter,
        })}`
      )

      this.data = data.data

      this.isFetching = false
    },
  },
}
</script>
