<template>
    <div>
        <div class="is-flex">
            <span class="button ml-auto" @click="openModal()">Ajouter un document</span>
        </div>
        <b-modal :active.sync="isModalActive" has-modal-card>
            <div class="modal-card">
                <form model="document" class="modal-card-body" @submit.prevent="submit">
                    <h2 style="text-align:center; font-size:1.1rem; font-weight:700">Ajout de documents</h2>

                    <b-field label="Equipement associé" name="equipment_name" class="mt-4" v-show="nbOptionEquipments>1">
                        <b-select
                            id="equipment"
                            placeholder="Sélectionner un équipement"
                            v-model="equipment"
                            v-if="nbOptionEquipments>0"
                            :disabled="nbOptionEquipments===1"
                        >
                            <option v-for="(label, code) in optionEquipments" :key="code" :value="code">{{ label }}</option>
                        </b-select>
                    </b-field>

                    <div class="mt-3">
                        <label for="file_document" style="font-weight:700">Sélectionnez un ou plusieurs documents</label>
                    </div>
                    <div class="mt-1">
                        <input id="file_document" type="file" multiple @change="selectFile" />
                    </div>

                    <div class="mt-3">
                        <label style="font-weight:700">Document(s) sélectionné(s)</label>
                    </div>
                    <ul v-if="progressInfos.length>0">
                        <li class="" v-for="(progressInfo, index) in progressInfos" :key="index">
                            <small>{{progressInfo.fileName}}&nbsp;: </small>
                            <small class="text-orange">{{progressInfo.info}}</small>
                        </li>
                    </ul>
                    <div v-if="progressInfos.length===0">
                        <small>Aucun.</small>
                    </div>

                    <div class="is-flex mt-4">
                        <div class="ml-auto" >
                            <b-button type="is-primary" native-type="submit" :loading="saving" :disabled="!selectedFiles">Envoyer</b-button>
                            <b-button class="ml-2" @click="cancel">Annuler</b-button>
                        </div>
                    </div>
                </form>
            </div>
        </b-modal>
    </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    data() {
        return {
            isModalActive: false, // modale affichée
            equipment: null, // code de l'éventuel équipement sélectionné
            progressInfos: [], // avancement du téléversement
            saving: false, // téléversement en cours
            selectedFiles: undefined, // liste des fichiers locaux sélectionnés
            optionEquipments: {}, // liste des équipements sélectionnables (uniquement ceux du client) ["code" => "label", ...]
            nbOptionEquipments: 0
        }
    },
    computed: {
        ...mapState({
            client: (state) => state.client,
            equipments: (state) => state.enums.equipments,
        }),
    },
    mounted() {
        // Liste des équipements sélectionnables (uniquement ceux du client) ["code" => "label", ...].
        this.optionEquipments = {}
        this.nbOptionEquipments = 0
        for(const code in this.equipments) {
            // Existe pour ce client ?
            for(let j=0; j<this.client.equipments.length; j++) {
                if ( this.client.equipments[j].type===code ) {
                    if ( this.optionEquipments[code]==undefined ) {
                        this.optionEquipments[code] = this.equipments[code];
                        this.nbOptionEquipments++;
                    }
                    break;
                }
            }
        }
        if ( this.nbOptionEquipments===1 ) {
            this.equipment = this.client.equipments[0].type
        }
        //console.log('this.optionEquipments', this.optionEquipments)
    },
    methods: {
        /**
         * Ouverture de la modale.
         */
        openModal() {
            this.isModalActive = true
            this.progressInfos = []
            this.saving = false
            this.selectedFiles = undefined
        },
        /**
         * Sélection d'un fichier local.
         * @param event
         */
        selectFile(event) {
            this.selectedFiles = event.target.files;

            this.progressInfos = [];
            for (let i = 0; i < this.selectedFiles.length; i++) {
                this.progressInfos.push({ fileName: this.selectedFiles[i].name, info: 'A téléverser', error: false });
            }
        },
        /**
         * Soumission du formulaire.
         * @returns {Promise<void>}
         */
        async submit() {
            // Téléversement des fichiers.
            let errors = false
            this.saving = true
            for (let i = 0; i < this.selectedFiles.length; i++) {
                await this.upload(i, this.selectedFiles[i]);
                if ( this.progressInfos[i].error ) {
                    errors = true
                }
            }
            this.saving = false

            // Fermeture de la modale.
            if ( !errors ) {
                this.$emit('refresh')
                this.isModalActive = false
            }
        },
        /**
         * Clic sur le bouton Annuler.
         * @return {Promise<void>}
         */
        async cancel() {
            // Note : il est nécessaire d'émettre l'événement "refresh" car des documents ont pu être ajoutés avec une erreur.
            this.$emit('refresh')
            this.isModalActive = false
        },
        /**
         * Téléversement d'un fichier.
         * @param idx
         * @param file
         * @returns {Promise<void>}
         */
        async upload(idx, file) {
            this.progressInfos[idx].info = 'Téléversement en cours...'

            // Données du formulaire à envoyer.
            let formData = new FormData()
            formData.append("file", file)
            formData.append("equipment", this.equipment)

            // Envoi.
            try {
                let { data } = await this.$axios({
                    method: 'post',
                    url: `/api/clients/${this.$route.params.id}/documents/add`,
                    data: formData,
                })
                this.progressInfos[idx].info = "Téléversement terminé."
            } catch (error) {
                this.progressInfos[idx].info = "Echec du téléversement !"
                this.progressInfos[idx].error = true
            }
        },
    },
}
</script>

<style lang="css" scoped>
.equipments__add {
    padding: 1rem 1rem 0 0;
    button {
        margin-left: auto;
    }
}
</style>
