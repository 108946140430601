<template>
  <div class="image-upload">
    <base-upload
      :id="id"
      v-model="file"
      accept="image/*"
      :expanded="expanded"
      :multiple="multiple"
      :drag-drop="dragDrop"
    ></base-upload>
    <figure class="image" v-if="media && !deleted">
      <img :src="media.url" :alt="media.name" />
      <button type="button" class="delete" @click="deleted = true"></button>
    </figure>
  </div>
</template>

<script>
import Upload from '@/mixins/upload'

export default {
  mixins: [Upload],
}
</script>

<style lang="scss" scoped>
.image-upload {
  figure {
    position: relative;

    img {
      transition: all 0.2s ease-out;
      max-width: 100%;
      width: auto;
    }

    .delete {
      position: absolute;
      right: 0.5rem;
      top: 0.5rem;
      transition: all 0.2s ease-out;
      visibility: hidden;
      opacity: 0;
    }

    &:hover {
      img {
        opacity: 0.2;
      }

      .delete {
        display: inline;
        visibility: visible;
        opacity: 1;
      }
    }
  }
}
</style>
