<template>
  <section>
    <div class="block">
      <b-checkbox
        v-for="(item, key) in data"
        :key="key"
        v-model="items"
        :native-value="item.key"
      >
        {{ item.value }}
      </b-checkbox>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Checkboxes',
  props: {
    value: Array,
    data: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    value(newValue) {
      this.items = newValue
    },
    items(newValue) {
      this.$emit('input', newValue)
    },
  },
  mounted() {
    this.items = this.value
  },
  data() {
    return {
      items: [],
    }
  },
}
</script>
