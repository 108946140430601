<script>
export default {
  inject: ['model'],
  props: {
    id: [String, Number],
    confirmTitle: String,
    confirmMessage: String,
    successMessage: String,
    softDelete: Boolean,
    model: String,
  },
  data: () => ({
    loading: false,
  }),
  methods: {
    onDelete() {
      this.$buefy.dialog.confirm({
        title: this.confirmTitle,
        message: this.confirmMessage,
        confirmText: this.softDelete ? 'Archiver' : 'Supprimer',
        cancelText: 'Annuler',
        type: 'is-warning',
        hasIcon: true,
        onConfirm: async () => {
          this.loading = true

          try {
            await this.$axios.delete(`/api/${this.model}/${this.id}`)
          }
          finally {
            this.loading = false
          }

          this.$buefy.toast.open({
            message: this.successMessage,
            type: 'is-success',
            position: 'is-bottom',
          })

          this.$emit('deleted')
        },
      })
    },
  },
}
</script>

<template>
  <b-button
    :title="softDelete ? 'Archiver' : 'Supprimer'"
    :icon-left="softDelete ? 'archive-outline' : 'trash-can-outline'"
    :loading="loading"
    @click="onDelete"
  />
</template>
