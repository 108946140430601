<template>
  <div>
    <div v-if="client.documents.length" class="mt-3" style="padding: 0 0 1.5rem 0">
      <div class="docCard">
        <Card class="mt-2 card p-2" v-for="item in reversedDocuments" :key="item.id" :document="item" @refresh="close"/>
      </div>
    </div>
    <div v-else>
      <h2 class="mt-2" style="text-align:center">Pas de documents</h2>
    </div>
  </div>
</template>

<script>
import Card from './card.vue'
import { mapState } from 'vuex'

export default {
  components: { Card },
  computed: {
    ...mapState({
      client: (state) => state.client,
    }),
    reversedDocuments() {
      return this.client.documents.slice().reverse(); // Crée une copie inversée du tableau
    },
  },
  methods: {
    close() {
      this.$emit('refresh')
    },
  },
}
</script>

<style  lang="scss" scoped>
.docCard{
  height: 550px;
  overflow-y: auto;

  // display: grid;
  // grid-template-columns: repeat(1, minmax(0, 1fr));
  // gap: 1rem;
  // @include desktop {
  //   grid-template-columns: repeat(3, minmax(0, 1fr));
  // }
}
</style>
