import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth'
import axios from '@/plugins/axios'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    enums: {},
    envs: {},
    response: {},
    client: {},
    equipment: {
      canceled_reason: undefined
    },
    refresh: false,
  },
  mutations: {
    setEnums(state, enums) {
      state.enums = enums
    },
    setEnvs(state, envs) {
      state.envs = envs
    },
    setResponse(state, response) {
      state.response = response
    },
    setClient(state, client) {
      state.client = client
    },
    setEquipment(state, equipment) {
      state.equipment = equipment
    },
    toggleRefresh(state) {
      state.refresh = !state.refresh
    },
  },
  actions: {
    loadEnums: async ({ commit }) => {
      let { data } = await axios.get('/api/referentiel')

      if (data) {
        commit('setEnums', data['enums'])
        commit('setEnvs', data['envs'])
      }
    },
  },
  modules: {
    auth,
  },
})
