<template>
  <import-form-layout
    :process="onImport"
    :template-url="templateUrl"
    @close="$emit('close')"
  >
    <template #title>
      <slot name="title"></slot>
    </template>

    <template #hint>
      <slot name="hint"></slot>
    </template>

    <div class="field">
      <b-switch v-model="email"
        >Activer l'envoi d'email lors de chaque élément importé</b-switch
      >
    </div>
  </import-form-layout>
</template>

<script>
import ImportFormLayout from './ImportFormLayout'

export default {
  components: { ImportFormLayout },
  props: {
    model: String,
  },
  data: () => ({
    email: true,
  }),
  computed: {
    templateUrl() {
      return `${this.$apiURL}/api/${this.model}/import/template`
    },
  },
  methods: {
    async onImport(form) {
      if (this.email) {
        form.append('email', this.email)
      }

      await this.$axios.post(`/api/${this.model}/import`, form)

      this.$emit('imported')
    },
  },
}
</script>
