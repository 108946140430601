<template>
  <div>
     <div class="ml-auto">
                <base-delete-button v-if="user.is_super_admin || user.is_admin"
                  :id="id"
                  model="equipments"
                  confirm-title="Supprimer un équipement"
                  confirm-message="Etes-vous sûr de vouloir supprimer cet équipement ? Cette action est irréversible !"
                  success-message="Equipement supprimé avec succès !"
                  @deleted="replace()"
                />
              </div>
  </div>
</template>

<script>
import { mapState} from 'vuex'
export default {
  props: {
    id: Number,
  },
  computed: {
    ...mapState({
      client: (state) => state.client,
      user: (state) => state.auth.user,
    }),
  },
  methods: {
    replace() {
      let newEquipment = this.client.equipments[0]

      // console.log(this.$route.query.equipment)
      // console.log(this.client.equipments)

      if(this.client.equipments.length < 2)
      {
      this.$emit('refresh')
      }

      if(this.$route.query.equipment == newEquipment.id)
      {
        newEquipment = this.client.equipments[1]
      }

      this.$router.push({
        name: this.$route.name,
        params: { id: this.$route.params.id },
        query: { equipment: newEquipment.id },
      })
      this.$emit('refresh')
    },
  },
}
</script>