import axios from '@/plugins/axios'

export default {
  namespaced: true,
  state: { user: null },
  mutations: {
    setUser(state, user) {
      state.user = user
    },
    removeUser(state) {
      state.user = null
    },
  },
  actions: {
    checkAuth: async ({ commit }) => {
      try {
        let { data } = await axios.get('/api/user')

        if (data) {
          commit('setUser', data)
        }
        return data
      } catch (e) {
        commit('removeUser')
      }
    },
  },
}
