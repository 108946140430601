<template>
  <div class="mt-5">
    <CommentForm v-if="this.user.is_super_admin || this.user.is_admin || this.user.is_user || this.user.is_commercial" @refresh="close"/>
    <CommentList @refresh="close"/>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import CommentForm from './form.vue'
import CommentList from './list.vue'

export default {
  data() {
    return {
      savLoading: false,
      cancelLoading: false,
      invoicingLoading: false,
      edition: false,
      form: {
        invoicing: null,
      },
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      client: (state) => state.client,
    }),
    fullInvoicing() {
      return (
        this.equipment.invoicing
      )
    },
    url() {
      return this.equipment
        ? `/api/clients/${this.client.id}/equipment/${this.equipment.id}`
        : null
    }
  },
  mounted() {
    if (this.equipment) {
      this.form.invoicing = this.equipment.invoicing
    }
  },
  methods: {
    ...mapMutations({
      setEquipment: 'setEquipment',
    }),
    async sav() {
      this.savLoading = true
      let { data } = await this.$axios({
        method: 'patch',
        url: this.url,
        data: {
          status: this.$workflow,
        },
      })
      this.savLoading = false
      this.$emit('refresh')
    },
    close() {
      this.$emit('refresh')
    },
  },
  components: { CommentForm, CommentList },
}
</script>
