<template>
    <div>
        <b-button class="mr-2" @click="isModalActive = true" v-if="this.user.is_super_admin || this.user.is_admin || this.user.is_user">Nouveau SAV</b-button>
        <b-modal :active.sync="isModalActive" has-modal-card>
            <div class="modal-card">
                <div class="modal-card-body">
                    <h2 class="text-center is-size-4">SAV</h2>
                    <SavForm @refresh="close" />
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import SavForm from './form.vue'

export default {
    data() {
        return {
            isModalActive: false,
        }
    },
    computed: {
        ...mapState({
            user: (state) => state.auth.user,
        }),
    },
    methods: {
        close() {
            this.isModalActive = false
            this.$emit('refresh')
        },
    },
    components: { SavForm },
}
</script>
