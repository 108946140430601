<template>
  <div class="media">
    <div class="media-left" v-if="model.logo_media">
      <img width="100" :src="model.logo_media.url" />
    </div>
    <div class="media-content">
      {{ model.name }}
      <template v-if="model.id_grc">
        <br />
        <small><strong>ID GRC :</strong> {{ model.id_grc }} </small>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    model: {
      type: Object,
      default: () => {},
    },
  },
}
</script>

<style lang="scss" scoped>
.media-content {
  margin: auto 0;
}
</style>
