'use strict'

import Vue from 'vue'
import axios from 'axios'
import trimEnd from 'lodash/trimEnd'

import { ToastProgrammatic as Toast } from 'buefy'

/**
 * Axios instance
 */
let baseURL = process.env.VUE_APP_API_URL || 'http://localhost:8000'
const _axios = axios.create({
  baseURL,
  withCredentials: true,
  headers: { 'X-Requested-With': 'XMLHttpRequest' },
})

_axios.interceptors.request.use(
  (config) => {
    // Do something before request is sent
    return config
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error)
  }
)

// Add a response interceptor
_axios.interceptors.response.use(
  (response) => {
    // Do something with response data
    return response
  },
  (e) => {
    let message = e.message

    if (e.response) {
      message = e.response.statusText

      if (e.response.status === 401) {
        return
      }
      if (e.response.data && e.response.data.message) {
        message = e.response.data.message
      }
    }

    Toast.open({
      message,
      type: 'is-warning',
      position: 'is-bottom',
      duration: 3000,
    })

    return Promise.reject(e)
  }
)

Plugin.install = (Vue) => {
  Object.defineProperties(Vue.prototype, {
    $axios: {
      get() {
        return _axios
      },
    },
    $apiURL: {
      get() {
        return trimEnd(baseURL, '/')
      },
    },
  })
}

Vue.use(Plugin)

export default _axios
