<template>
  <div>
    <div class="is-flex">
      <span class="button ml-auto" @click="openModal()">
       Ajouter un commentaire
      </span>
    </div>
    <b-modal :active.sync="isModalActive" has-modal-card>
      <div class="modal-card">
        <form model="document" class="modal-card-body" @submit.prevent="submit">
          <h2 style="text-align:center; font-size:1.1rem; font-weight:700">Ajout d'un commentaire</h2>
          <b-field
            class="mt-4"
            label="Commentaire"
            name="content"
          >
            <b-input
              id="content"
              v-model="form.content"
              expanded
              type="textarea"
              required
            ></b-input>
          </b-field>

          <div class="is-flex">
             <div class="ml-auto" >
              <b-button type="is-primary" native-type="submit" :loading="saving">
                Enregistrer
              </b-button>
               <b-button class="ml-2" @click="isModalActive = false">
               Annuler
            </b-button>
            </div>
          </div>
        </form>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data() {
    return {
      isModalActive: false,
      form: {
        content: null,
      },
      saving: false,
    }
  },
  computed: {
    ...mapState({
      client: (state) => state.client
    }),
  },
  methods: {
    openModal()
    {
      this.isModalActive = true
      this.form.content = null
    },
    async submit() {
      this.saving = true
      let toast = {
        message: 'Commentaire ajouté',
        type: 'is-success',
      }

      let send = this.form
      let formData = new FormData()
      Object.entries(send).forEach(([key, value]) => {
        formData.append(key, value)
      })
      send = formData

      
        try {
          let { data } = await this.$axios({
            method: 'post',
            url: `/api/clients/${this.$route.params.id}/comment`,
            data: send,
          })
        } catch (error) {
          toast = {
            message: 'Erreur',
            type: 'is-failed',
          }
        }
      

      this.saving = false
      this.$emit('refresh')
      this.isModalActive = false
    },

  },
}
</script>

<style lang="css" scoped>
.equipments__add {
  padding: 1rem 1rem 0rem 0rem;
  button {
    margin-left: auto;
  }
}
</style>
