<template>
  <div>
    <base-field
      horizontal
      label="Choix de la formule*"
      name="pack_id"
      v-if="packs.length"
    >
      <b-radio-button
        v-for="(pack, i) in packs"
        :key="i"
        v-model="form.pack_id"
        :native-value="pack.id"
        type="is-success is-light is-outlined"
      >
        {{ pack.label }} ({{
          pack.price.toLocaleString('fr-FR', {
            style: 'currency',
            currency: 'EUR',
          })
        }})
      </b-radio-button>
    </base-field>
    <base-field
      horizontal
      label="Contenu de la formule"
      v-if="contentOfSelectedPack"
    >
      <div v-html="contentOfSelectedPack" class="mb-5"></div>
    </base-field>
    <base-field
      horizontal
      label="Type d'installation*"
      name="installation_id"
      v-if="prices.length"
    >
      <b-radio-button
        v-for="(installation, i) in prices"
        :key="i"
        v-model="form.installation_id"
        :native-value="installation.id"
        type="is-success is-light is-outlined"
      >
        {{ priceTypes[installation.name] }} ({{
          installation.amount.toLocaleString('fr-FR', {
            style: 'currency',
            currency: 'EUR',
          })
        }})
      </b-radio-button>
    </base-field>
    <b-field horizontal label="Options" name="options" v-if="hasOptions">
      <section>
        <div>
          <b-checkbox
            v-model="form.options"
            native-value="second_fall_detector"
          >
            Second détecteur de chute ({{
              optionsOfSelectedPack['second_fall_detector']
            }}
            €)
          </b-checkbox>
        </div>
        <div>
          <b-checkbox v-model="form.options" native-value="mobility">
            Mobilité ({{ optionsOfSelectedPack['mobility'] }} €)
          </b-checkbox>
        </div>
      </section>
    </b-field>
    <b-field
      horizontal
      label="Avantages"
      v-if="form.pack_id && form.installation_id && benefits.length"
    >
      <section>
        <div v-for="(benefit, i) in benefits" :key="i">
          <b-checkbox v-model="form.benefits" :native-value="benefit.id">
            {{ benefit.label }}
          </b-checkbox>
        </div>
      </section>
    </b-field>
    <b-field
      horizontal
      label="Tarif abonnement"
      name="final_price_subscription"
      v-if="form.pack_id"
    >
      <div>
        <strong>{{
          form.final_price_subscription.toLocaleString('fr-FR', {
            style: 'currency',
            currency: 'EUR',
          })
        }}</strong>
        <span class="ml-2" v-if="additionalPriceTexts.length">{{
          additionalPriceTexts.join(', ')
        }}</span>
      </div>
    </b-field>
    <b-field
      horizontal
      :label="`Frais ${installationLabel}`"
      name="final_price_installation"
      v-if="form.installation_id"
    >
      <strong>{{
        form.final_price_installation.toLocaleString('fr-FR', {
          style: 'currency',
          currency: 'EUR',
        })
      }}</strong>
    </b-field>

    <div
      class="mt-6"
      v-if="form.pack_id && form.installation_id"
      v-html="fallAlertText"
    ></div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      additionalPriceTexts: [],
      texts: [],
      packs: [],
      prices: [],
      benefits: [],
    }
  },
  async mounted() {
    await this.fetchPacks()
    await this.fetchPrices()
    await this.fetchTexts()
    await this.fetchBenefits()

    this.calculateFinalPrices()
  },
  computed: {
    ...mapState({
      priceTypes: (state) => state.enums.priceTypes,
    }),
    installationLabel() {
      let price = this.prices.find(
        (price) => price.id === this.form.installation_id
      )
      return price ? this.priceTypes[price.name]?.toLowerCase() : 'installation'
    },
    selectedPack() {
      return this.packs.find((pack) => pack.id === this.form.pack_id)
    },
    contentOfSelectedPack() {
      return this.selectedPack?.content || ''
    },
    optionsOfSelectedPack() {
      return this.selectedPack?.options || {}
    },
    hasOptions() {
      return (
        Object.keys(this.optionsOfSelectedPack).filter(
          (key) => this.optionsOfSelectedPack[key] > 0
        ).length > 0
      )
    },
    fallAlertText() {
      return this.texts.find((text) => text.name === 'TAP_FALL_ALERT_INFO')
        ?.content
    },
    form: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  watch: {
    'form.pack_id': function () {
      this.form.options = []
    },
    form: {
      handler() {
        this.calculateFinalPrices()
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    calculateFinalPrices() {
      let finalPriceInstallation =
        this.prices.find((price) => price.id === this.form.installation_id)
          ?.amount || 0

      let finalPriceSubscription =
        this.packs.find((pack) => pack.id === this.form.pack_id)?.price || 0

      if (this.form.options.length) {
        finalPriceSubscription += this.form.options.reduce(
          (acc, key) => acc + this.optionsOfSelectedPack[key],
          0
        )
      }

      if (this.form.benefits.length) {
        this.additionalPriceTexts = []

        finalPriceInstallation -= this.calculateBenefits(
          'installation',
          finalPriceInstallation
        )
        finalPriceSubscription -= this.calculateBenefits(
          'subscription',
          finalPriceSubscription
        )
      }

      this.form.final_price_installation =
        finalPriceInstallation < 0 ? 0 : finalPriceInstallation
      this.form.final_price_subscription =
        finalPriceSubscription < 0 ? 0 : finalPriceSubscription
    },
    calculateBenefits(priceType, price) {
      return this.benefits
        .filter(
          (benefit) =>
            this.form.benefits.includes(benefit.id) && benefit[priceType]
        )
        .reduce((acc, benefit) => {
          switch (benefit.discount_unit) {
            case 'PERCENT':
              return acc + (price * benefit.discount_amount) / 100
            case 'AMOUNT':
              return acc + benefit.discount_amount
            case 'MONTH':
              if (priceType === 'subscription') {
                this.additionalPriceTexts = [
                  ...this.additionalPriceTexts,
                  benefit.description,
                ]
              }
          }
          return acc
        }, 0)
    },
    async fetchPacks() {
      let { data } = await this.$axios.get('/api/packs?active=true')

      this.packs = data
    },
    async fetchPrices() {
      let { data } = await this.$axios.get('/api/prices')

      this.prices = data
    },
    async fetchTexts() {
      let { data } = await this.$axios.get('/api/texts')

      this.texts = data
    },
    async fetchBenefits() {
      let { data } = await this.$axios.get('/api/benefits?active=true')

      this.benefits = data
    },
  },
}
</script>

<style lang="scss" scoped>
fieldset {
  margin-bottom: 1.5rem;
}
legend {
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 1rem;
}
</style>
