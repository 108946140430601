<template>
  <b-autocomplete
    :id="id"
    :data="data"
    :placeholder="placeholder"
    :loading="isFetching"
    :value="value"
    @typing="getAsyncData"
    @input="(val) => $emit('input', val)"
  >
  </b-autocomplete>
</template>

<script>
import debounce from 'lodash/debounce'
import qs from 'qs'

let fetchApi = (query) => {
  return fetch(`https://geo.api.gouv.fr/communes?${qs.stringify(query)}`, {
    method: 'GET',
    redirect: 'follow',
  }).then((response) => response.json())
}

export default {
  props: {
    id: String,
    value: String,
    placeholder: {
      type: String,
      default: 'Rechercher une ville',
    },
    perPage: {
      type: Number,
      default: 10,
    },
  },
  data() {
    return {
      data: [],
      isFetching: false,
    }
  },
  methods: {
    getAsyncData: debounce(async function (q) {
      if (!q.length) {
        this.data = []
        return
      }

      let cities = []

      this.isFetching = true

      /**
       * On test sur nom puis code postal si aucun résultat
       */
      let data = await fetchApi({
        nom: q,
        boost: 'population',
        limit: this.perPage,
      })

      if (data.length) {
        /**
         * Décomposition par code postal
         */
        data.forEach((c) => {
          cities.push(
            ...(c.codesPostaux || []).map((cp) => {
              return `${cp} - ${c.nom}`
            })
          )
        })
      } else {
        /**
         * Test via code postal
         */
        data = await fetchApi({
          codePostal: q,
        })

        cities = data.map((c) => {
          return `${q} - ${c.nom}`
        })
      }

      this.data = cities

      this.isFetching = false
    }, 500),
  },
}
</script>

<style scoped>
/deep/ .dropdown-content {
  max-height: 400px !important;
}
</style>
