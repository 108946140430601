<template>
  <div class="columns">
    <div class="column">
      <div class="data-card">
        <h3>Nbre de jour pour<br />ouvrir une indication</h3>
        <span :class="getColor(daysBeforeOpen, 2, 4)" class="value">{{
          daysBeforeOpen
        }}</span>
      </div>
    </div>
    <div class="column">
      <div class="data-card">
        <h3>Nbre de jour pour<br />traiter une indication</h3>
        <span :class="getColor(daysBeforeProcess, 2, 4)" class="value">{{
          daysBeforeProcess
        }}</span>
      </div>
    </div>
    <div class="column" v-if="conventionTotal">
      <div class="data-card">
        <h3>Nbre de convention en attente</h3>
        <span :class="getColor(conventionPending, 5, 10)" class="value">{{
          conventionPending
        }}</span>
        /
        {{ conventionTotal }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    daysBeforeOpen: Number,
    daysBeforeProcess: Number,
    conventionTotal: Number,
    conventionPending: Number,
  },
  methods: {
    getColor(value, successThreshold, warningThreshold) {
      if (value <= successThreshold) {
        return 'has-text-success'
      }
      if (value <= warningThreshold) {
        return 'has-text-warning'
      }
      return 'has-text-danger'
    },
  },
}
</script>

<style lang="scss" scoped>
.data-card {
  background: $bg-grey;
  padding: 0.75rem;
  text-align: center;

  h3 {
    font-weight: bold;
    font-size: 0.8rem;
  }

  .value {
    font-size: 3rem;
  }
}
</style>
