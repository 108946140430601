<template>
  <div class="app-layout">
    <app-header />
    <app-navbar />
    <main>
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </main>
    <app-footer />
  </div>
</template>

<script>
import AppHeader from '../components/AppHeader'
import AppNavbar from '../components/AppNavbar'
import AppFooter from '../components/AppFooter'

export default {
  components: {
    AppHeader,
    AppNavbar,
    AppFooter,
  },
}
</script>

<style lang="scss" scoped>
.app-layout {
  display: flex;
  flex-direction: column;
  height: 100vh;

  > * {
    flex-shrink: 0;
  }
}

main {
  background: $bg-grey;
  padding: 1rem;
  flex: 1 0 auto;

  @include tablet {
    padding: 3rem 0;
  }
}
</style>
