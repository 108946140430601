<template>
  <div class="b-steps is-large is-vertical">
    <nav class="steps has-label-right mobile-minimalist">
      <ul class="step-items">
        <li
          v-for="(step, i) in steps"
          :key="i"
          class="step-item"
          :class="{
            'is-active': activeItem && activeItem.value === step.value,
            'is-previous': activeItem && steps.indexOf(activeItem) > i,
          }"
        >
          <a class="step-link">
            <div class="step-marker">
              <b-icon :icon="step.icon"></b-icon>
            </div>
            <div class="step-details">
              <slot v-bind="step"></slot>
            </div>
          </a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  props: {
    value: [String, Number],
    steps: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    activeItem() {
      return this.steps.find((i) => i.value === this.value)
    },
  },
}
</script>
