<template>
  <div class="password">
    <div class="card">
      <div class="card-content">
        <h4 class="header-text-message">Changer mon mot de passe</h4>

        <form class="password-form" @submit.prevent="onChange">
          <b-field
            label="Ancien mot de passe"
            label-for="current_password"
            :type="type"
            :message="errors.current_password"
          >
            <b-input
              id="current_password"
              type="password"
              required
              v-model="form.current_password"
            >
            </b-input>
          </b-field>
          <password-check ref="password-check" />

          <b-button class="block mt-4" native-type="submit" :loading="pending">
            Modifier
          </b-button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import PasswordCheck from '@/components/PasswordCheck.vue'
export default {
  components: { PasswordCheck },
  data: () => ({
    form: {
      current_password: null,
      password: null,
      password_confirmation: null,
    },
    type: null,
    errors: {},
    pending: false,
  }),
  methods: {
    async onChange() {
      this.pending = true

      let passwordCheck = this.$refs['password-check']

      let form = this.form
      form.password = passwordCheck.form.password
      form.password_confirmation = passwordCheck.form.password_confirmation

      try {
        await this.$axios.put('/auth/user/password', form)

        this.form = {
          current_password: null,
        }
        passwordCheck.form = {
          password: null,
          password_confirmation: null,
        }
        this.type = null
        this.errors = {}

        this.$buefy.toast.open({
          duration: 5000,
          message: 'Mot de passe modifié avec succès',
          position: 'is-bottom',
          type: 'is-success',
        })
      } catch (e) {
        if (e.response.status === 422) {
          this.type = 'is-warning'
          this.errors = e.response.data.errors
        }
      } finally {
        this.pending = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.password {
  margin-top: 3rem;

  @include tablet {
    display: flex;
    justify-content: center;
  }
}

.card {
  padding: 1.5rem;

  @include tablet {
    width: 600px;
    padding: 3rem;
  }
}

/deep/ .label {
  font-weight: normal;

  &:not(:last-child) {
    margin-bottom: 0;
  }
}

/deep/ .field:not(:last-child) {
  margin-bottom: 2rem;
}

/deep/ .input {
  height: 3.5rem !important;
}
</style>
