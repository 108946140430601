<template>
  <div v-if="savItems.length" class="mt-3 card p-5">
    <h2 class="text-center is-size-4">SAV</h2>
    <Card v-for="item in savItems" :key="item.id" :sav-item="item" @refresh="close"/>
  </div>
</template>

<script>
import Card from './card.vue'

export default {
  props: {
    savItems: {
      type: Array,
      default: () => [],
    },
  },
  components: { Card },
  methods: {
    close() {
      this.$emit('refresh')
    },
  },
}
</script>
