<template>
  <!--
  <b-button
    icon-left="pencil-outline"
    tag="router-link"
    :to="`/${model}/${id}/edit`"
    title="Editer"
  >
  </b-button>
  -->
  <a :href="`/${model}/${id}/edit`" class="button" type="button" title="Editer">
    <span class="icon is-small"><i class="mdi mdi-pencil-outline"></i></span>
  </a>
</template>

<script>
export default {
  props: {
    id: [String, Number],
  },
  inject: ['model'],
}
</script>
