<script>
import {mapState} from "vuex";

export default {
  props: {
    client: {
      type: Number,
    },
    equipment: {
      type: Number,
    },
    // Type d'événement : 'r1', 'r2'
    ics: {
      type: String,
    },
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapState({
      app_url: (state) => state.envs.app_url,
    }),
  },
  methods: {
  },
}
</script>
<template>
  <div>
    <b-button
      tag="a"
      :href="`${app_url}/api/clients/${client}/equipment/${equipment}/ics/${ics}`"
      size="is-small"
    >Ajouter dans Outlook</b-button>
  </div>
</template>
