<template>
    <div class="login">
        <div class="panel">
            <div class="logo">
                <img
                    alt="Solutions de protections connectées"
                    src="../assets/logo.png"
                    width="250"
                />
            </div>

            <div v-show="mode===MODE_GAUTHIQ">
                <div class="header-text-message">
                    Redirection vers l'authentification Groupama...
                </div>
            </div>

            <div v-show="mode===MODE_INTERNE">
                <div class="header-text-message">
                    Veuillez vous identifier pour accéder à votre espace :
                </div>
                <form class="login-form" @submit.prevent="onLogin">
                    <b-field label="Email" label-for="email">
                        <b-input id="email" type="email" required v-model="form.email">
                        </b-input>
                    </b-field>

                    <b-field label="Mot de passe" label-for="password">
                        <b-input
                            id="password"
                            type="password"
                            required
                            v-model="form.password"
                        >
                        </b-input>
                    </b-field>

                    <b-field>
                        <div class="remember">
                            <b-checkbox v-model="form.remember">Se souvenir de moi</b-checkbox>
                            <a href="javascript:void(0)" @click="isPasswordResetModalActive = true">Mot de passe oublié ?</a>
                        </div>
                    </b-field>
                    <b-button class="block" native-type="submit" :loading="pending">
                        Se connecter
                    </b-button>
                </form>
                <b-message
                    auto-close
                    :active.sync="isMessageActive"
                    type="is-warning"
                    :duration="5000"
                >
                    {{ errorMessage }}
                </b-message>
            </div>

            <!--
            <HelpMessage />
            <span v-if="version !== 'null'" class="app-version">
                    {{ version }}
            </span>
            -->
        </div>

        <b-modal :active.sync="isPasswordResetModalActive" has-modal-card>
            <reset-form :email="form.email"></reset-form>
        </b-modal>
    </div>
</template>

<script>
import HelpMessage from '@/components/HelpMessage.vue'
import ResetForm from '@/components/ResetForm.vue'
import {mapState} from "vuex";
import isEmpty from 'lodash/isEmpty'

export default {
    components: {
        HelpMessage,
        ResetForm,
    },
    data: () => ({
        form: {
            email: null,
            password: null,
            remember: false,
        },
        isPasswordResetModalActive: false,
        errorMessage: null,
        isMessageActive: false,
        pending: false,
        version: process.env.VUE_APP_VERSION,
        mode: null, // mode d'authentification choisi. Valeurs possibles : MODE_*
        MODE_GAUTHIQ: 'G', // Gauthiq, par défaut
        MODE_INTERNE: 'I', // Interne à l'application, activé si /login/interne
    }),
    computed: {
        ...mapState({
            envs: (state) => state.envs,
        }),
        /**
         * Indique si on utilise Gauthiq pour l'authentification.
         */
        useGauthiq() {
            return !isEmpty(this.envs.login_gauthiq)
        }
    },
    methods: {
        /**
         * Indique si la chaîne est vide ou indéfinie.
         * @param chaine
         */
        empty(chaine) {
            return isEmpty(chaine);
        },
        async onLogin() {
            this.pending = true

            try {
                await this.$axios.post('/auth/login', this.form)

                // Avec le "reload", on évite l'erreur : Error: Redirected when going from "/login" to "/dashboard" via a navigation guard
                // Doc ici : https://stackoverflow.com/questions/62223195/vue-router-uncaught-in-promise-error-redirected-from-login-to-via-a
                //this.$router.push({ name: 'Home' });
                window.location.reload();
            } catch (e) {
                if (!e.response) {
                    this.errorMessage = e.message
                } else if (e.response.data.errors) {
                    this.errorMessage = e.response.data.errors.email[0]
                }
                this.isMessageActive = true
            }
            this.pending = false
        },
    },
    beforeMount() {
        this.mode = !this.useGauthiq || (this.$route.params.mode != undefined && this.$route.params.mode == 'interne') ? this.MODE_INTERNE : this.MODE_GAUTHIQ
        console.log('useGauthiq', this.useGauthiq, 'param', this.$route.params.mode, 'mode', this.mode)
    },
    mounted() {
        if ( this.mode === this.MODE_GAUTHIQ ) {
            setTimeout(() => {
                // Redirection vers Gauthiq.
                window.location.href = this.envs.login_gauthiq
            }, 1500)
        }
    }
}
</script>

<style lang="scss" scoped>
.logo {
    text-align: center;
}

.login {
    background: url(/splash.webp) no-repeat;
    background-size: cover;
    background-position: 350px;
    height: 100%;
    min-height: 100vh;
}

.login-form {
    margin-bottom: 1.5rem;
}

.panel {
    overflow: auto;
    background: $white;
    scrollbar-width: thin;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 2rem;
    border-radius: 0;

    @include tablet {
        width: 550px;
        padding: 4rem;
    }
}

.header-text-message {
    margin: 3rem 0 2rem;
}

.texte-normal-centre {
    margin-top: 1rem;
    text-align: center;
}

a {
    color: $orange;
    text-decoration: underline;
}

.remember {
    display: flex;
    align-items: center;
    justify-content: space-between;
    a {
        font-style: italic;
    }
}

.app-version {
    color: $grey;
    font-size: 0.8rem;
    position: absolute;
    bottom: 0.5rem;
    right: 0.5rem;
}

/deep/ .label {
    font-weight: normal;

    &:not(:last-child) {
        margin-bottom: 0;
    }
}

/deep/ .field:not(:last-child) {
    margin-bottom: 2rem;
}

/deep/ .input {
    height: 3.5rem !important;
}
</style>
