<template>
  <b-button
    icon-left="magnify"
    tag="router-link"
    :to="`/${model}/${id}`"
    title="Voir"
  >
  </b-button>
</template>

<script>
export default {
  props: {
    id: [String, Number],
  },
  inject: ['model'],
}
</script>
