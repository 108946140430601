<template>
  <div class="chart">
    <div class="chart__body">
      <chart-wrapper
        class="chart__component"
        type="doughnut"
        :chart-data="chartData"
        :options="options"
      ></chart-wrapper>
      <span class="centered-text">
        <slot></slot>
      </span>
    </div>
    <div class="chart__legend" v-if="labels.length">
      <ul>
        <li v-for="({ label, color }, i) in labels" :key="i">
          <span
            class="legend__marker"
            :style="{
              'background-color': color,
            }"
          ></span>
          <span class="legend__label">
            {{ label }}
            <strong
              >({{
                countFormatter ? countFormatter(data[i]) : data[i]
              }})</strong
            >
          </span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import ChartWrapper from '@/components/charts/ChartWrapper.vue'
import sum from 'lodash/sum'

export default {
  components: {
    ChartWrapper,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    labels: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Object,
      default: () => {},
    },
    countFormatter: Function,
  },
  computed: {
    chartData() {
      if (sum(this.data)) {
        let labels = this.labels.filter((l) => !l.hidden)

        return {
          datasets: [
            {
              data: this.data.slice(0, labels.length),
              backgroundColor: labels.map((l) => l.color),
            },
          ],

          labels: labels.map((l) => l.label),
        }
      }

      /**
       * Empty data
       */
      return {
        datasets: [
          {
            data: [1],
          },
        ],

        labels: ['Aucune donnée'],
      }
    },
  },
}
</script>


<style lang="scss" scoped>
.chart {
  display: flex;
  justify-content: space-around;
  .chart__body {
    width: 70%;
    max-width: 350px;
    position: relative;
    .chart__component {
      z-index: 1;
    }
    .centered-text {
      position: absolute;
      top: 170px;
      left: 50%;
      transform: translate(-50%, -50%);
      white-space: nowrap;
      text-align: center;
      font-size: 1.8rem;
      line-height: 1;
    }
  }
  .chart__legend {
    display: flex;
    align-items: center;
    padding-left: 10px;
    li {
      position: relative;
      &:not(:last-child) {
        margin-bottom: 1rem;
      }
      .legend__marker {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
      .legend__label {
        display: block;
        margin-left: 1.5rem;
        font-size: 0.8rem;
      }
    }
  }
}
</style>
