<template>
  <div class="container">
    <h1>{{ $route.meta.title }}</h1>
    <client-form></client-form>
  </div>
</template>

<script>
import ClientForm from './Form'

export default {
  components: { ClientForm },
}
</script>
