<template>
  <b-field
    :label-for="labelFor || name"
    :label="label"
    :type="type"
    :message="messages.length ? messages : message"
    :horizontal="horizontal"
  >
    <template #label>
      <b-tooltip v-if="tooltip" multilined type="is-dark">
        {{ label }}
        <template v-slot:content>
          <span v-html="tooltip"></span>
        </template>
        <b-icon size="is-small" icon="help-circle-outline"></b-icon>
      </b-tooltip>
      <span v-else>
        {{ label }}
        <span class="alert">
          {{ alert }}
        </span>
      </span>
    </template>
    <slot></slot>
  </b-field>
</template>

<script>
export default {
  props: {
    label: String,
    name: String,
    labelFor: String,
    message: String,
    horizontal: Boolean,
    mask: [String, Object],
    tooltip: String,
    alert: String,
  },
  inject: ['state'],
  data: () => ({
    type: null,
    messages: [],
  }),
  watch: {
    'state.errors'(e) {
      this.type = null
      this.messages = []

      if (e[this.name]) {
        this.type = 'is-warning'
        this.messages = e[this.name]
      }
    },
  },
}
</script>

<style scoped>
/deep/ .alert {
  font-weight: bold;
  color: red;
}
</style>
