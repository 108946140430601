<template>
  <div class="configurator">
    <div class="is-flex">
      <span class="button ml-auto" @click="onAdd()">Ajouter un élément</span>
    </div>

    <div class="mt-3">
      <b-table :data="items">
        <b-table-column field="name" label="Nom" v-slot="props">
          {{ types[props.row.name] }}
        </b-table-column>
        <b-table-column field="amount" label="Prix" v-slot="props">
          {{
            props.row.amount.toLocaleString('fr-FR', {
              style: 'currency',
              currency: 'EUR',
            })
          }}
        </b-table-column>
        <b-table-column field="id" label="Actions" v-slot="props">
          <div>
            <b-button
              title="Editer"
              icon-left="pencil-outline"
              @click="onEdit(props.row)"
              class="mr-2"
            />
            <b-button
              title="Supprimer"
              icon-left="trash-can-outline"
              @click="onDelete(props.row.id)"
            />
          </div>
        </b-table-column>
        <template #empty>
          <div class="has-text-centered">Vide</div>
        </template>
      </b-table>
    </div>

    <b-modal :active.sync="isModalActive" has-modal-card>
      <div class="modal-card">
        <form class="modal-card-body" @submit.prevent="submit">
          <h2
            style="
              text-align: center;
              font-size: 1.1rem;
              font-weight: 700;
              margin-bottom: 1rem;
            "
          >
            <span v-if="!itemId">Ajouter</span>
            <span v-else>Edition #{{ itemId }}</span>
          </h2>

          <base-field label="Nom*" name="name" horizontal>
            <b-select
              id="label"
              v-model="form.name"
              :placeholder="'Sélectionnez un type'"
            >
              <option
                v-for="(label, value) in types"
                :key="value"
                :value="value"
              >
                {{ label }}
              </option>
            </b-select>
          </base-field>

          <base-field label="Montant*" name="amount" horizontal>
            <b-input id="amount" v-model="form.amount"></b-input>
          </base-field>

          <div class="is-flex mt-5">
            <div class="ml-auto">
              <b-button
                type="is-primary"
                native-type="submit"
                :loading="saving"
              >
                <span v-if="itemId">Ajouter</span>
                <span v-else>Enregistrer</span>
              </b-button>
              <b-button class="ml-2" @click="isModalActive = false"
                >Annuler</b-button
              >
            </div>
          </div>
        </form>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data() {
    return {
      isModalActive: false, // modale affichée
      itemId: null,
      form: {
        name: null,
        amount: 0,
      }, // valeurs par défaut pour un nouveau type
      state: {
        errors: {},
      }, // Note : juste pour le fonctionnement de base-field (Field) qui devrait normalement être placé dans base-model-form (ModelForm).
      saving: false, // enregistrement en cours
      // TODO
      items: [],
      api: 'prices',
    }
  },
  provide() {
    // Note : juste pour le fonctionnement de base-field (Field) qui devrait normalement être placé dans base-model-form (ModelForm).
    return {
      state: this.state,
    }
  },
  computed: {
    ...mapState({
      types: (state) => state.enums.priceTypes,
    }),
  },
  mounted() {
    this.loadItems()
  },
  methods: {
    async loadItems() {
      let { data } = await this.$axios.get(`/api/${this.api}`)

      this.items = data
    },
    /**
     * Clic sur le bouton d'ajout.
     */
    onAdd() {
      this.itemId = null
      this.form = { name: null, amount: 0 }
      this.openModal()
    },
    /**
     * Clic sur le bouton de suppression.
     */
    async onDelete(id) {
      this.$buefy.dialog.confirm({
        title: "Suppression d'un élément",
        message: `Etes-vous sûr de vouloir supprimer #"${id}" ? Cette action est irréversible !`,
        confirmText: 'Oui, supprimer',
        cancelText: 'Annuler',
        type: 'is-warning',
        hasIcon: true,
        onConfirm: async () => {
          await this.$axios.delete(`/api/${this.api}/${id}`)
          this.$buefy.toast.open({
            message: `L'élément #"${id}" a été supprimé avec succès !`,
            type: 'is-success',
            position: 'is-bottom',
          })
          await this.loadItems()
        },
      })
    },
    /**
     * Clic sur le bouton d'édition.
     */
    async onEdit(item) {
      this.itemId = item.id
      const { name, amount } = item
      this.form = { name, amount }
      this.openModal()
    },
    /**
     * Ouverture de la modale.
     */
    openModal() {
      this.isModalActive = true
      this.saving = false
    },
    /**
     * Soumission du formulaire.
     * @returns {Promise<void>}
     */
    async submit() {
      let errors = false
      this.saving = true
      try {
        await this.$axios({
          method: this.itemId ? 'patch' : 'post',
          url: this.itemId
            ? `/api/${this.api}/${this.itemId}`
            : `/api/${this.api}`,
          data: this.form,
        })
        errors = false
        this.$buefy.toast.open({
          message: 'Les données ont été enregistrées avec succès !',
          type: 'is-success',
          position: 'is-bottom',
        })
      } catch (e) {
        errors = true

        if (e.response && e.response.status === 422) {
          this.state.errors = e.response.data.errors
        } else {
          this.$buefy.toast.open({
            message: e.response.data.message,
            type: 'is-failed',
            position: 'is-bottom',
          })
        }
      }
      await this.loadItems()
      this.saving = false

      // Fermeture de la modale.
      if (!errors) {
        this.$emit('refresh')
        this.isModalActive = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.configurator {
  max-width: 700px;
}
</style>
