<template>
  <footer>
    <div>
      <router-link :to="{ name: 'Legal' }"> Mentions légales </router-link>
      <router-link :to="{ name: 'PersonalData' }">
        Données personnelles
      </router-link>
    </div>

    <span v-if="version !== 'null'" class="app-version">
      {{ version }}
    </span>
  </footer>
</template>

<script>
export default {
  data: () => ({
    version: process.env.VUE_APP_VERSION,
  }),
}
</script>

<style lang="scss" scoped>
footer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $white;
  padding: 1rem;

  a {
    color: inherit;
    margin-right: 1.5rem;
  }

  .app-version {
    display: none;
  }

  @include tablet {
    .app-version {
      display: block;
      position: absolute;
      right: 1rem;
      top: 50%;
      transform: translateY(-50%);
      color: $grey;
      font-size: 0.8rem;
    }
  }
}
</style>
