<template>
    <div>
        <div>
            <div v-if="document.equipment && document.equipment != 'null'" class="mb-2" style="font-weight:bold">
                {{equipments[document.equipment]}}
            </div>
            <div  v-else class="mb-2" style="font-weight:bold">
                Document client
            </div>
            <div class="mb-2 ml-4 is-flex" style="align-items:center">
                <a :href="document.url" rel="noopener noreferrer" class="link">{{document.name}}</a>
                <a :href="document.url" rel="noopener noreferrer" class="ml-4">
                    <b-button :title="'Télécharger'" :icon-left="'download-outline'" />
                </a>
                <base-delete-button v-if="this.user.is_super_admin || this.user.is_admin || this.user.is_user || this.user.is_commercial"
                                    :id="document.id"
                                    model="document"
                                    class="ml-2"
                                    confirm-title="Supprimer un document"
                                    confirm-message="Etes-vous sûr de vouloir supprimer ce document ? Cette action est irréversible !"
                                    success-message="Document supprimé avec succès !"
                                    @deleted="reload()"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import Form from './form.vue'
export default {
    props: {
        document: {
            type: Object,
            default: () => {},
        },
    },
    computed: {
        ...mapState({
            user: (state) => state.auth.user,
            equipments: (state) => state.enums.equipments,
        }),
    },
    data() {
        return {
            edition: false,
        }
    },
    components: { Form },
    methods: {
        reload() {
            this.$emit('refresh')
        },
    }
}
</script>

<style lang="scss" scoped>
.sav-item-card {
    border-bottom: 1px solid gray;
    padding-bottom: 1rem;
    padding-top: 1rem;
    position: relative;
    .card-edit {
        position: absolute;
        top: 0.75rem;
        right: 0;
    }
}
</style>
