<template>
  <div class="file-upload">
    <base-upload
      :id="id"
      v-model="file"
      :expanded="expanded"
      :multiple="multiple"
      :drag-drop="dragDrop"
    ></base-upload>
    <div class="file-preview" v-if="media && !deleted">
      <a :href="media.url" target="_blank" class="tag">
        {{ media.file_name }}
      </a>
      <button type="button" class="delete" @click="deleted = true"></button>
    </div>
  </div>
</template>

<script>
import Upload from '@/mixins/upload'

export default {
  mixins: [Upload],
}
</script>

<style lang="scss" scoped>
.file-preview {
  margin-top: 0.5rem;
  display: flex;
  align-items: center;

  .delete {
    margin-left: 0.5rem;
  }
}
</style>
