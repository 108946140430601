<template>
  <div>
    <nprogress-container></nprogress-container>
    <router-view />
  </div>
</template>

<script>
import NprogressContainer from 'vue-nprogress/src/NprogressContainer'
export default {
  components: {
    NprogressContainer,
  },
}
</script>

<style lang="scss">
html,
body {
  background: $bg-grey;
  height: 100%;
}
</style>
