<template>
  <div class="card">
    <div class="card-content">
      <h2>Équipements</h2>
      <div v-for="(type, id) in types" :key="id" class="equipment-bar">
        <div class="label">
          <div>{{ type.name }}</div>
          <div>{{ type.size }}</div>
        </div>
        <div class="bar">
          <div class="bar-active" :style="`width: ${type.percent}%;`"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    query: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      types: [],
      maximum: 0,
      currentQuery: '',
    }
  },
  computed: {
    ...mapState({
      equipments: (state) => state.enums.equipments,
    }),
  },
  watch: {
    query: function (newVal, oldVal) {
      this.currentQuery = newVal
      this.getStatistics()
    },
  },
  mounted() {
    this.currentQuery = this.query
    this.getStatistics()
  },
  methods: {
    async getStatistics() {
      let response = await this.$axios.get(
        `/api/statistics/equipments-type${this.currentQuery}`
      )
      this.parseStats(response.data)
    },
    parseStats(raw) {
      this.types = []
      raw.forEach((el) => {
        this.types.push({
          id: el.type,
          name: this.equipments[el.type],
          size: el.total,
          percent: 0,
        })
      })
      const max = this.types.reduce((prev, current) =>
        prev.size > current.size ? prev : current
      )
      this.maximum = max

      this.types.forEach((el) => {
        el.percent = (el.size / this.maximum.size) * 100
      })
    },
    randomIntFromInterval(min, max) {
      return Math.floor(Math.random() * (max - min + 1) + min)
    },
  },
}
</script>

<style lang="scss" scoped>
.card {
  padding: 1rem;
}
h2 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}
.equipment-bar {
  margin-top: 0.25rem;
  .label {
    display: flex;
    justify-content: space-between;
  }
  .bar {
    background-color: lightgray;
    border-radius: 2rem;
    height: 0.6rem;
    & .bar-active {
      background-color: #ea5e34;
      border-radius: 2rem;
      height: 0.6rem;
    }
  }
}
</style>
