<template>
  <b-taginput
    :id="id"
    :data="data"
    :placeholder="placeholder"
    :field="field"
    :loading="isFetching"
    :value="items"
    :size="size"
    autocomplete
    @typing="getAsyncData"
    @add="onAdd"
    @remove="onRemove"
  >
    <template slot-scope="props">
      <slot v-bind="props"></slot>
    </template>
  </b-taginput>
</template>

<script>
import debounce from 'lodash/debounce'
import qs from 'qs'

export default {
  props: {
    id: String,
    value: {
      type: Array,
      default: () => [],
    },
    trackedBy: {
      type: String,
      default: 'id',
    },
    field: String,
    model: String,
    enums: {
      type: Object,
      default: null,
    },
    size: String,
    placeholder: String,
    perPage: {
      type: Number,
      default: 10,
    },
  },
  data() {
    return {
      data: [],
      enumsList: [],
      items: [],
      isFetching: false,
    }
  },
  watch: {
    value: {
      handler(newVal) {
        if (newVal.length) {
          this.getModels(newVal)
        } else {
          this.items = []
        }
      },
      immediate: true,
    },
  },
  mounted() {
    if (this.enums) {
      for (let [key, value] of Object.entries(this.enums)) {
        this.enumsList.push({
          key: key,
          value: value,
        })
      }
      this.data = this.enumsList
    }
  },
  methods: {
    onAdd(option) {
      let value = [...this.value]
      const index = value.indexOf(option[this.trackedBy])

      if (index === -1) {
        value.push(option[this.trackedBy])
      }
      this.$emit('input', value)
    },
    onRemove(option) {
      let value = [...this.value]
      const index = value.indexOf(option[this.trackedBy])

      if (index !== -1) {
        value.splice(index, 1)
      }
      this.$emit('input', value)
    },
    async getModels(id) {
      this.isFetching = true

      let { data } = await this.$axios.get(
        `/api/${this.model}?${qs.stringify({
          filter: {
            id: id.join(','),
          },
        })}`
      )

      this.items = data.data
      this.isFetching = false
    },
    getEnums(text) {
      return this.enumsList.filter((option) => {
        return (
          option.value.toString().toLowerCase().indexOf(text.toLowerCase()) >= 0
        )
      })
    },
    getAsyncData: debounce(async function (q) {
      if (!q.length) {
        this.data = []
        return
      }

      this.isFetching = true
      if (this.enums !== null) {
        this.data = this.getEnums(q)
      } else {
        let { data } = await this.$axios.get(
          `/api/${this.model}?${qs.stringify({
            filter: {
              q,
            },
            perPage: this.perPage,
            page: 1,
          })}`
        )

        this.data = data.data
      }
      this.isFetching = false
    }, 500),
  },
}
</script>
