<template>
  <b-button
    @click="onToggle"
    :title="value ? 'Désactiver' : 'Activer'"
    :icon-left="value ? iconActivate : iconDesactivate"
    :loading="loading"
  >
  </b-button>
</template>

<script>
export default {
  props: {
    id: [String, Number],
    field: String,
    value: Boolean,
    confirmTitle: Function,
    confirmMessage: Function,
    successMessage: Function,
    iconActivate: {
      type: String,
      default: 'check',
    },
    iconDesactivate: {
      type: String,
      default: 'close',
    },
  },
  inject: ['model'],
  data: () => ({
    loading: false,
  }),
  watch: {
    value() {
      this.loading = false
    },
  },
  methods: {
    async onToggle() {
      this.$buefy.dialog.confirm({
        title: this.confirmTitle(!this.value),
        message: this.confirmMessage(!this.value),
        confirmText: this.value ? 'Désactiver' : 'Activer',
        cancelText: 'Annuler',
        type: 'is-warning',
        hasIcon: true,
        onConfirm: async () => {
          this.loading = true

          await this.$axios.patch(`/api/${this.model}/${this.id}`, {
            [this.field]: !this.value,
          })

          this.$buefy.toast.open({
            message: this.successMessage(!this.value),
            type: this.value ? 'is-warning' : 'is-success',
            position: 'is-bottom',
            duration: 3000,
          })

          this.$emit('toggled')
        },
      })
    },
  },
}
</script>
