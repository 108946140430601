<template>
  <div class="file-collection columns">
    <base-file
      v-for="file in files"
      :key="file.id"
      :value="file"
      class="column is-one-third"
      :deletable="deletable"
    />
  </div>
</template>

<script>
export default {
  props: {
    files: {
      type: Array,
      default: () => [],
    },
    deletable: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.file-collection {
  flex-wrap: wrap;
}
</style>
