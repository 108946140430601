<template>
  <b-autocomplete
    :id="id"
    :data="data"
    :placeholder="placeholder"
    :custom-formatter="text"
    :loading="isFetching"
    :size="size"
    clearable
    v-model="label"
    @typing="getAsyncData"
    @select="onSelect"
  >
    <template slot-scope="props">
      <slot v-bind="props">
        {{ text(props.option) }}
      </slot>
    </template>
  </b-autocomplete>
</template>

<script>
import debounce from 'lodash/debounce'
import qs from 'qs'

export default {
  props: {
    id: String,
    value: {
      type: [Object, Number, String],
      default: () => {},
    },
    trackedBy: {
      type: String,
      default: 'id',
    },
    filter: {
      type: Object,
      default: () => {},
    },
    text: Function,
    model: String,
    size: String,
    placeholder: String,
    perPage: {
      type: Number,
      default: 10,
    },
  },
  data() {
    return {
      data: [],
      isFetching: false,
      label: null,
    }
  },
  watch: {
    label(newVal) {
      if (!newVal) {
        this.$emit('input', null)
      }
    },
    value: {
      handler(newVal) {
        if (newVal) {
          this.getModel(newVal)
        } else {
          this.label = null
        }
      },
      immediate: true,
    },
  },
  methods: {
    onSelect(option) {
      if (option) {
        this.$emit('input', option[this.trackedBy])
      }
      this.$emit('select', option)
    },
    async getModel(id) {
      this.isFetching = true

      let { data } = await this.$axios.get(`/api/${this.model}/${id}`)

      this.label = this.text(data)
      this.isFetching = false
    },
    getAsyncData: debounce(async function (q) {
      if (!q.length) {
        this.data = []
        return
      }

      this.isFetching = true

      let { data } = await this.$axios.get(
        `/api/${this.model}?${qs.stringify({
          filter: {
            q,
            ...this.filter,
          },
          perPage: this.perPage,
          page: 1,
        })}`
      )

      this.data = data.data

      this.isFetching = false
    }, 500),
  },
}
</script>
