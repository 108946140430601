<template>
  <div class="container is-fluid">
    <h1>Statistiques</h1>
    <dashboard></dashboard>
    <!-- <bar-chart /> -->
  </div>
</template>

<script>
import { mapState } from 'vuex'

import Dashboard from '@/components/statistics/Index.vue'
import BarChart from '@/components/charts/BarChart.vue'

export default {
  components: {
    Dashboard,
    BarChart,
  },
  data() {
    return {
      chart: [],
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
}
</script>
