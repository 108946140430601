<template>
  <span class="tag is-success" v-if="value">
    {{ trueValue }}
  </span>
  <span class="tag is-warning" v-else>
    {{ falseValue }}
  </span>
</template>

<script>
export default {
  props: {
    value: Boolean,
    trueValue: {
      type: String,
      default: 'Oui',
    },
    falseValue: {
      type: String,
      default: 'Non',
    },
  },
}
</script>
