<template>
  <div>
    <div>
      <label class="label">Libellé du Pack :</label>
      <span>{{ subscription.pack_label }}</span>
    </div>
    <div>
      <label class="label">Prix du Pack :</label>
      <span>{{
        subscription.pack_price.toLocaleString('fr-FR', {
          style: 'currency',
          currency: 'EUR',
        })
      }}</span>
    </div>
    <div>
      <label class="label">Contenu du Pack :</label>
      <div v-html="subscription.pack_content" class="mb-2"></div>
    </div>
    <div>
      <label class="label">Type d'installation :</label>
      <span>{{ priceTypes[subscription.installation_type] }}</span>
    </div>
    <div>
      <label class="label">Prix d'installation :</label>
      <span>{{
        subscription.installation_price.toLocaleString('fr-FR', {
          style: 'currency',
          currency: 'EUR',
        })
      }}</span>
    </div>
    <div
      v-if="
        getOptionPrice('second_fall_detector') || getOptionPrice('mobility')
      "
    >
      <label class="label">Options :</label>
      <ul class="mb-2">
        <li v-if="getOptionPrice('second_fall_detector')">
          {{ isOptionChecked('second_fall_detector') }} 1 second détecteur chute
          +
          {{
            getOptionPrice('second_fall_detector').toLocaleString('fr-FR', {
              style: 'currency',
              currency: 'EUR',
            })
          }}
        </li>
        <li v-if="getOptionPrice('mobility')">
          {{ isOptionChecked('mobility') }} Option Mobilité +
          {{
            getOptionPrice('mobility').toLocaleString('fr-FR', {
              style: 'currency',
              currency: 'EUR',
            })
          }}
        </li>
      </ul>
    </div>
    <div>
      <label class="label">Avantages :</label>
      <ul class="mb-2">
        <li v-for="(benefit, i) in subscription.benefits" :key="i">
          {{ benefit.description }}
        </li>
      </ul>
    </div>
    <div>
      <label class="label">Prix final abonnement :</label>
      <span
        >{{
          subscription.final_price_subscription.toLocaleString('fr-FR', {
            style: 'currency',
            currency: 'EUR',
          })
        }}/mois</span
      >
    </div>
    <div>
      <label class="label">Prix final installation :</label>
      <span>{{
        subscription.final_price_installation.toLocaleString('fr-FR', {
          style: 'currency',
          currency: 'EUR',
        })
      }}</span>
    </div>
    <div v-if="canEdit">
      <b-button @click="$emit('edit')" type="is-primary"
        >Modifier la formule</b-button
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    subscription: {
      type: Object,
      required: true,
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    priceTypes() {
      return {
        1: 'Installation à domicile',
        2: 'Installation en agence',
      }
    },
  },
  methods: {
    getOption(option) {
      return this.subscription.options[option]
    },
    isOptionChecked(option) {
      return this.getOption(option)?.checked ? '☒' : '☐'
    },
    getOptionPrice(option) {
      return this.getOption(option)?.price || 0
    },
  },
}
</script>

<style lang="scss" scoped>
.label {
  display: inline-block;
  margin-right: 0.5rem;
}
</style>
