import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import axios from '@/plugins/axios'

import MainLayout from '../layouts/Main'

import Login from '../views/Login'
import PasswordChange from '../views/PasswordChange'
import PasswordReset from '../views/PasswordReset'
import Error from '../views/Error'
import Home from '../views/Home'
import Legal from '../views/Legal'
import PersonalData from '../views/PersonalData'

import UserList from '../views/users/List'
import UserCreate from '../views/users/Create'
import UserEdit from '../views/users/Edit'

import ClientList from '../views/clients/List'
import ClientCreate from '../views/clients/Create'
import ClientEdit from '../views/clients/Edit'
import ClientShow from '../views/clients/Show'

import EquipmentList from '../views/equipments/List'

import Statistics from '../views/Statistics'

import Configuration from '../views/Configuration'

let appTitle = 'Groupama TLB'

let resources = {
  users: "L'utilisateur",
}

const redirectToErrorPage = (e, model, { next }) => {
  switch (e.response.status) {
    case 403:
      next({
        name: '403',
        params: {
          status: 403,
          message: `${resources[model]} n'est plus disponible`,
        },
      })
      break
    case 404:
      next({
        name: '404',
        params: {
          status: 404,
          message: `${resources[model]} n'est plus disponible`,
        },
      })
      break
    case 500:
      next({
        name: '500',
        params: {
          status: 500,
          message: 'Erreur serveur',
        },
      })
      break
  }
}

const redirectWithItem = async (model, title, { to, next, url, headers }) => {
  try {
    let url = `/api/${model}/${to.params.id}`
    let { data } = await axios.get(url, {
      headers,
    })

    to.meta.model = data
    to.meta.title = typeof title === 'string' ? title : title(data)
    to.meta.url = url
    document.title = `${to.meta.title} | ${appTitle}`
    next()
  } catch (e) {
    redirectToErrorPage(e, model, { id: to.params.id, next })
  }
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/login/:mode?', // mode = interne pour connexion d'un utilisateur interne à l'application, gauthiq sinon par défaut.
    name: 'Login',
    component: Login,
    meta: {
      public: true,
      redirect: true,
    },
  },
  {
    path: '/',
    component: MainLayout,
    children: [
      {
        path: '',
        redirect: '/dashboard',
      },
      {
        path: '/dashboard',
        name: 'Home',
        component: Home,
        meta: {
          title: 'Tableau de bord',
        },
      },
      {
        path: '/legal',
        name: 'Legal',
        component: Legal,
        meta: {
          title: 'Mentions légales',
        },
      },
      {
        path: '/personal-data',
        name: 'PersonalData',
        component: PersonalData,
        meta: {
          title: 'Données personnelles',
        },
      },
      {
        path: '/password/change',
        name: 'PasswordChange',
        props: true,
        component: PasswordChange,
        meta: {
          title: 'Modifier mon mot de passe',
        },
      },
      {
        path: '/password/reset/:token',
        name: 'PasswordReset',
        props: true,
        component: PasswordReset,
        meta: {
          public: true,
          redirect: true,
          title: 'Modifier mon mot de passe',
        },
      },
      {
        path: '/statistics',
        name: 'Statistics',
        component: Statistics,
        meta: {
          title: 'Statistiques',
        },
      },
      {
        path: '/configuration',
        name: 'Configuration',
        component: Configuration,
        meta: {
          title: 'Configuration',
        },
      },
      {
        path: '/clients',
        component: {
          render(c) {
            return c('router-view')
          },
        },
        meta: {
          // deny: (user) => user.is_user,
        },
        children: [
          {
            path: '',
            name: 'ClientList',
            component: ClientList,
            beforeEnter: async (to, from, next) => {
              to.meta.filter = null
              to.meta.title = 'Liste des clients'

              document.title = `${to.meta.firstname} ${to.meta.lastname} | ${appTitle}`
              next()
            },
          },
          {
            path: 'create',
            name: 'ClientCreate',
            component: ClientCreate,
            meta: {
              title: 'Créer un client',
            },
          },
          {
            path: ':id',
            name: 'ClientShow',
            component: ClientShow,
            beforeEnter: (to, from, next) =>
              redirectWithItem(
                'clients',
                (model) =>
                  `Voir le client "${model.firstname} ${model.lastname}"`,
                {
                  to,
                  next,
                }
              ),
          },
          {
            path: ':id/edit',
            name: 'ClientEdit',
            component: ClientEdit,
            beforeEnter: (to, from, next) =>
              redirectWithItem(
                'clients',
                (model) =>
                  `Editer le client "${model.firstname} ${model.lastname}"`,
                {
                  to,
                  next,
                }
              ),
          },
        ],
      },
      {
        path: '/equipments',
        component: {
          render(c) {
            return c('router-view')
          },
        },
        meta: {
          // deny: (user) => user.is_user,
        },
        children: [
          {
            path: '',
            name: 'EquipmentList',
            component: EquipmentList,
            beforeEnter: async (to, from, next) => {
              to.meta.filter = null
              to.meta.title = 'Liste des équipements'

              document.title = `${to.meta.type} | ${appTitle}`
              next()
            },
          },
        ],
      },
      {
        path: '/users',
        component: {
          render(c) {
            return c('router-view')
          },
        },
        meta: {
          // Seul le SUPER_ADMIN a besoin de gérer les utilisateurs internes à l'application car tous les autres comptes sont gérés par Gauthiq.
          //deny: (user) => !user.is_super_admin && !user.is_admin,
          deny: (user) => !user.is_super_admin
        },
        children: [
          {
            path: '',
            name: 'UserList',
            component: UserList,
            beforeEnter: async (to, from, next) => {
              to.meta.filter = null
              to.meta.title = 'Liste des utilisateurs'

              if (to.query.partner_id) {
                to.meta.filter = { partner_id: to.query.partner_id }

                try {
                  let { data } = await axios.get(
                    `/api/partners/${to.query.partner_id}`
                  )
                  to.meta.title = `Liste des utilisateurs du partenaire ${data.name}`
                } catch (e) {
                  redirectToErrorPage(e, 'partners', {
                    id: to.query.partner_id,
                    next,
                  })
                }
              } else if (to.query.entity_id) {
                to.meta.filter = { entity_id: to.query.entity_id }

                try {
                  let { data } = await axios.get(
                    `/api/entities/${to.query.entity_id}`
                  )
                  to.meta.title = `Liste des utilisateurs de l'entité ${data.name}`
                } catch (e) {
                  redirectToErrorPage(e, 'entities', {
                    id: to.query.entity_id,
                    next,
                  })
                }
              }

              document.title = `${to.meta.title} | ${appTitle}`
              next()
            },
          },
          {
            path: 'create',
            name: 'UserCreate',
            component: UserCreate,
            meta: {
              title: 'Créer un utilisateur',
            },
          },
          {
            path: ':id/edit',
            name: 'UserEdit',
            component: UserEdit,
            beforeEnter: (to, from, next) =>
              redirectWithItem(
                'users',
                (model) => `Editer l'utilisateur "${model.name}"`,
                {
                  to,
                  next,
                }
              ),
          },
        ],
      },
      {
        path: '/403',
        name: '403',
        component: Error,
        props: true,
      },
      {
        path: '/404',
        name: '404',
        component: Error,
        props: true,
      },
      {
        path: '/500',
        name: '500',
        component: Error,
        props: true,
      },
      {
        path: '*',
        redirect: '404',
      },
    ],
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes,
})

router.beforeEach(async (to, from, next) => {
  // Titre générique
  document.title =
    to.meta && to.meta.title ? `${to.meta.title} | ${appTitle}` : appTitle

  // Chargement des enums
  await store.dispatch('loadEnums')

  // Utilisateur courant
  let user = await store.dispatch('auth/checkAuth')

  if (!to.meta.public) {
    // Espace privé.
    if (!user) {
      // Visiteur anonyme. Authentification requise.
      await router.push({ path: '/login' })
      return
    }

    // Cas d'accès limités à certains profils d'utilisateur.
    to.matched.forEach((r) => {
      if (r.meta.deny && r.meta.deny(user)) {
        // Ce profil n'a pas accès.
        return next({
          name: '403',
          params: {
            status: 403,
            message: `Accès à cette page non autorisé`,
          },
        })
      }
    })

    // Accès autorisé.
    return next()
  }

  // Espace public.
  if (user && to.meta.redirect) {
    // Pour cette URL, on redirige l'utilisateur authentifié vers l'accueil de l'espace privé.
    await router.push({ path: '/dashboard' })
    return
  }

  next()
})

router.redirectWithItem = redirectWithItem

export default router
