<template>
  <form @submit.prevent="onSubmit()">
    <slot></slot>
  </form>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import pick from 'lodash/pick'

export default {
  props: {
    model: {
      type: String,
      required: true,
    },
    saving: Boolean,
    value: {
      type: Object,
      default: () => {},
    },
    default: {
      type: Object,
      default: () => {},
    },
    modelName: String,
    /**
     * Instant de gêne
     */
    modelFeminine: Boolean,
    redirect: [String, Object],
    customUrl: String,
    replace: {
      type: [Object, Function],
      default: () => {},
    },
    customMethod: {
      type: String,
      default: null,
      validator: (value) => ['post', 'patch'].includes(value),
    },
    updated: {
      type: Boolean,
      default: false,
    },
  },
  provide() {
    return {
      state: this.state,
    }
  },
  data: () => ({
    id: null,
    state: {
      errors: {},
    },
  }),
  computed: {
    // ...mapState({
    //   setResponse()
    // }),
  },
  watch: {
    default: {
      handler(newVal) {
        if (newVal) {
          this.id = newVal.id

          this.$emit('input', {
            ...this.value,
            ...pick(newVal, Object.keys(this.value)),
          })
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapMutations({
      setResponse: 'setResponse',
      toggleRefresh: 'toggleRefresh',
    }),
    onSubmit() {
      this.submit()
    },
    async submit() {
      this.$emit('update:saving', true)
      try {
        let { data } = await this.$axios({
          method: this.customMethod
            ? this.customMethod
            : this.id
            ? 'patch'
            : 'post',
          url:
            this.customUrl !== undefined
              ? this.customUrl
              : this.id
              ? `/api/${this.model}/${this.id}`
              : `/api/${this.model}`,
          data: this.value,
        })
        //console.log(this.value)
        //console.log('response, data', data)
        this.setResponse(data)
        this.toggleRefresh()
        if (this.redirect) {
          if (this.redirect === 'model') {
            this.$router.push({
              name: 'ClientShow',
              params: {
                id: data.id,
              },
            })
          } else {
            this.$router.push(this.redirect)
          }
        }
        if (this.replace) {
          this.replace()
        }

        this.$buefy.toast.open({
          duration: 5000,
          message: `${this.modelName} ${
            this.id || this.updated ? 'modifié' : 'ajouté'
          }${this.modelFeminine ? 'e' : ''} avec succès`,
          position: 'is-bottom',
          type: 'is-success',
        })
      } catch (e) {
        console.error(e)
        if (e.response && e.response.status === 422) {
          this.state.errors = e.response.data.errors
        }
      } finally {
        this.$emit('update:saving', false)
      }
    },
  },
}
</script>
