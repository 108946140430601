<template>
  <section>
    <div class="block">
      <b-radio
        v-for="(item, key) in data"
        :key="key"
        :name="name"
        v-model="selected"
        :native-value="item.key"
        :required="required"
      >
        <b-tooltip v-if="item.tooltip" type="is-dark" :label="item.tooltip">
          {{ item.value }}
          <b-icon size="is-small" icon="help-circle-outline"></b-icon>
        </b-tooltip>
        <span v-else>
          {{ item.value }}
        </span>
      </b-radio>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Radios',
  props: {
    value: [String, Object],
    data: {
      type: Array,
      default: () => [],
    },
    name: {
      type: String,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    value(newValue) {
      this.selected = newValue
    },
    selected(newValue) {
      this.$emit('input', newValue)
    },
  },
  mounted() {
    this.selected = this.value
  },
  data() {
    return {
      selected: null,
    }
  },
}
</script>
