<template>
  <div class="workflow-wrapper">
    <div class="workflow" :style="`--bar-width-var: ${barWidth}%;`">
      <div class="list">
        <div v-for="(item, id) in list" :key="id" class="item">
          <span
            :class="item.isDeployed ? 'deployed' : ''"
            class="item_workflow"
          >
            <component :is="`icon-${item.icon}`" class="icon" />
            <div>
              <span class="status">{{ item.label }}</span>
              <span class="date">
                <span v-if="item.isDeployed">
                  {{ $formatDate(item.date, 'dd/MM/yyyy') }}
                </span>
                <span v-else>
                  <span v-if="[id - 1] > 0 && list[id - 1].isDeployed"
                    >En cours</span
                  >
                  <span v-else>En attente</span>
                </span>
              </span>
            </div>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import UploadDoc from '@/components/clients/documents/form.vue'
export default {
  data() {
    return {
      list: [],
      barWidth: 0,
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      workflow: (state) => state.enums.workflow,
      workflowFailed: (state) => state.enums.workflowFailed,
      workflowQuotationRejected: (state) => state.enums.workflowQuotationRejected,
      workflowInstallationAbandon: (state) => state.enums.workflowInstallationAbandon,
      workflowCanceled: (state) => state.enums.workflowCanceled,
      refresh: (state) => state.refresh,
      client: (state) => state.client,
      equipment: (state) => state.equipment,
    }),
  },
  mounted() {
    this.list = this.getList()
    this.barWidth = this.getBarWidth()
  },
  watch: {
    // '$route.query.equipment'(newValue) {
    //   this.refreshWorkflow()
    // },
    equipment(newValue) {
      this.refreshWorkflow()
    },
    // refresh(newValue) {
    //   this.refreshWorkflow()
    // },
  },
  methods: {
    refreshWorkflow() {
      this.list = this.getList()
      this.barWidth = this.getBarWidth()
    },
    close() {
      this.$emit('refresh')
    },
    getList() {
      let list = []
      if (this.equipment && this.equipment !== {}) {
        let workflow = this.workflow
        if ( this.equipment.status === this.$workflow.ABANDONMENT ) {
          if ( this.equipment.canceled_reason==undefined || this.equipment.canceled_reason === this.$workflow.NO_ELIGIBLE ) {
            workflow = this.workflowFailed
          } else if ( this.equipment.canceled_reason === this.$workflow.QUOTATION_REJECTED ) {
            workflow = this.workflowQuotationRejected
          }
        } else if ( this.equipment.status === this.$workflow.CANCELED ) {
          workflow = this.workflowCanceled
        }
          else if ( this.equipment.status === this.$workflow.INSTALLATION_ABANDON ) {
            workflow = this.workflowInstallationAbandon
          }
        Object.entries(workflow).forEach((element) => {
          let label = element[0]
          let value = element[1]
          let isDeployed = this.equipment
            ? this.equipment.status_updated_at[value] !== undefined
            : false
          list.push({
            icon: value.toLowerCase().replace('_', '-'),
            value: value,
            label: label,
            isDeployed,
            date: isDeployed ? this.equipment.status_updated_at[value] : null,
          })
        })
      }
      return list
    },
    getBarWidth() {
      let deployed = this.list.filter((e) => e.isDeployed)
      let unit = 100 / (this.list.length - 1)
      let value = unit * deployed.length
      return value < 100 ? value : 100
    },
  },
  components: {
    UploadDoc
  },
}
</script>

<style lang="scss" scoped>
.workflow-wrapper {
  padding: 1rem 2rem;
  .workflow {
    position: relative;
    margin: 2rem;
    .list {
      justify-content: space-between;
      display: flex;
      z-index: 10;
      position: relative;
      list-style: none;
      margin: 0;
      padding: 0;
    }
    .item {
      padding-inline: 0.5rem;
      background-color: $bg-grey;
      align-items: center;
      display: flex;
      .deployed {
        color: $red-tlb;
        opacity: 1 !important;
      }
      .item_workflow {
        text-align: center;
        width: 1.5rem;
        height: 1.5rem;
        position: relative;
        opacity: 0.5;
        .icon {
          position: absolute;
          width: 2rem;
          height: 2rem;
          background-color: $bg-grey;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        div {
          position: absolute;
          height: 2rem;
          bottom: -2rem;
          width: 10rem;
          left: 50%;
          transform: translateX(-50%);
          .status {
            margin-top: 0.3rem;
            font-size: 0.7rem;
            font-weight: bold;
            display: block;
          }
          .date {
            font-size: 0.6rem;
            display: block;
            margin-top: 0.1rem;
          }
        }
      }
    }
  }
  .workflow::before {
    background-color: rgba($gray, 0.5);
    border-radius: 0.5rem;
    height: 0.05rem;
    display: block;
    top: 50%;
    left: 0;
    right: 0;
    position: absolute;
    content: '';
  }
  .workflow::after {
    background-color: rgba($red-tlb, 0.5);
    border-radius: 0.5rem;
    width: var(--bar-width-var);
    height: 0.05rem;
    display: block;
    top: 50%;
    left: 0;
    right: 0;
    position: absolute;
    content: '';
  }
}
</style>
