<template>
  <form @submit.prevent="onSendLink">
    <div class="modal-card">
      <section class="modal-card-body">
        <h4 class="header-text-message">
          Entrez l'e-mail associé à votre compte pour recevoir le lien.
        </h4>

        <b-message
          auto-close
          :active.sync="isMessageActive"
          :type="messageType"
          :duration="5000"
        >
          {{ message }}
        </b-message>
        <b-field label="Email" label-for="reset-email">
          <b-input id="reset-email" type="email" v-model="form.email" required>
          </b-input>
        </b-field>
        <b-button class="block" native-type="submit" :loading="pending">
          Envoyer
        </b-button>
      </section>
    </div>
  </form>
</template>

<script>
export default {
  props: {
    email: String,
  },
  data: () => ({
    form: {
      email: null,
    },
    message: null,
    isMessageActive: false,
    messageType: null,
    pending: false,
  }),
  watch: {
    email: {
      handler(newVal) {
        this.form.email = newVal
      },
      immediate: true,
    },
  },
  methods: {
    async onSendLink() {
      this.pending = true

      try {
        let { data } = await this.$axios.post(
          '/auth/forgot-password',
          this.form
        )

        this.messageType = 'is-success'
        this.message = data.message
        this.isMessageActive = true
      } catch (e) {
        this.messageType = 'is-warning'
        if (!e.response) {
          this.message = e.message
        } else if (e.response.data.errors) {
          this.message = e.response.data.errors.email[0]
        }
        this.isMessageActive = true
      }
      this.pending = false
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-card {
  width: 90%;
  margin: 0 auto !important;

  .modal-card-body {
    padding: 4rem;
  }

  @include tablet {
    width: 600px;
  }
}
</style>
