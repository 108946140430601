<template>
  <div class="card" :class="{ horizontal }">
    <div class="card-content">
      <div class="filter__header">
        <h3>Filtrer</h3>
        <b-button @click="clear"> Effacer les filtres </b-button>
      </div>
      <div class="filter__form">
        <b-field label="Matériel" label-for="type">
          <b-select
            id="type"
            expanded
            v-model="form.type"
            placeholder="Sélectionner un matériel"
          >
            <option
              v-for="(name, value) in equipments"
              :value="value"
              :key="value"
            >
              {{ name }}
            </option>
          </b-select>
        </b-field>
        <b-field label="En SAV" label-for="sav">
          <b-select
            id="sav"
            expanded
            v-model="form.sav"
            placeholder="Sélectionner un statut"
          >
            <option
              v-for="(name, value) in {
                true: 'Oui',
                false: 'Non',
              }"
              :value="value"
              :key="value"
            >
              {{ name }}
            </option>
          </b-select>
        </b-field>
        <b-field label="Commercial" label-for="commercial_id">
          <base-autocomplete
            id="commercial_id"
            v-model="form.commercial_id"
            model="commercials"
            placeholder="Rechercher un commercial"
            :text="(model) => model.name"
          >
            <template slot-scope="props">
              {{ props.option.name }} ({{ props.option.email }})
            </template>
          </base-autocomplete>
        </b-field>
        <b-field label="CSP" label-for="csp">
          <b-select
            id="csp"
            expanded
            v-model="form.csp"
            placeholder="Sélectionner un CSP"
          >
            <option
              v-for="(name, value) in contracts"
              :value="value"
              :key="value"
            >
              {{ name }}
            </option>
          </b-select>
        </b-field>
        <b-field label="Période" label-for="period">
          <b-select
            id="period"
            expanded
            v-model="period"
            placeholder="Sélectionner une période"
          >
            <option
              v-for="(name, value) in periods"
              :value="value"
              :key="value"
            >
              {{ name }}
            </option>
          </b-select>
        </b-field>
        <b-field label="Du" label-for="started_at">
          <base-datepicker
            id="started_at"
            icon="calendar-today"
            trap-focus
            v-model="form.started_at"
            @input="period = null"
            with-time
          ></base-datepicker>
        </b-field>
        <b-field label="Au" label-for="ended_at">
          <base-datepicker
            id="ended_at"
            icon="calendar-today"
            trap-focus
            v-model="form.ended_at"
            @input="period = null"
          ></base-datepicker>
        </b-field>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import qs from 'qs'
import EntityTemplate from '@/components/statistics/EntityTemplate.vue'
import { format as formatDate } from 'date-fns'

export default {
  components: {
    EntityTemplate,
  },
  props: {
    horizontal: Boolean,
  },
  data: () => ({
    form: {
      commercial_id: null,
      type: null,
      user_id: null,
      department: null,
      started_at: null,
      ended_at: null,
      csp: null,
    },
    period: null,
    periods: {
      current_year: 'Année en cours',
      previous_year: 'Année dernière',
      current_month: 'Mois en cours',
      half_year: '6 derniers mois',
    },
    query: '',
  }),
  watch: {
    period(newVal) {
      /**
       * Calcul de la période
       */
      let now = new Date()

      let started_at, ended_at

      switch (newVal) {
        case 'current_year':
          started_at = new Date(now.getFullYear(), 0, 1)
          ended_at = new Date(now.getFullYear(), 11, 31)
          break
        case 'previous_year':
          started_at = new Date(now.getFullYear() - 1, 0, 1)
          ended_at = new Date(now.getFullYear() - 1, 11, 31)
          break
        case 'current_month':
          started_at = new Date(now.getFullYear(), now.getMonth(), 1)
          ended_at = new Date(now.getFullYear(), now.getMonth() + 1, 0)
          break
        case 'half_year':
          started_at = new Date(now.getFullYear(), now.getMonth() - 6, 1)
          ended_at = new Date(now.getFullYear(), now.getMonth(), 0)
          break
      }

      this.form = {
        ...this.form,
        started_at: formatDate(new Date(started_at), 'yyyy-MM-dd'),
        ended_at: formatDate(new Date(ended_at), 'yyyy-MM-dd'),
      }
    },
    form: {
      handler() {
        this.$router.push({ query: { ...this.backendQuery } }).catch(() => {})
        this.loadAsyncData()
      },
      deep: true,
    },
  },
  mounted() {
    /**
     * Synchroniser avec la query du router
     * Le watcher du form effectuera la requête automatiquement
     */
    this.initFromQuery()
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      contracts: (state) => state.enums.contracts,
      equipments: (state) => state.enums.equipments,
    }),
    backendQuery() {
      let q = Object.keys(this.form).reduce((o, key) => {
        let v = this.form[key]

        if (v) {
          o[key] = Array.isArray(v) ? v.join(',') : v
        }

        return o
      }, {})

      return q
    },
  },
  methods: {
    clear() {
      this.form = {
        entity_id: null,
        type: null,
        user_id: null,
        department: null,
        started_at: null,
        ended_at: null,
        csp: null,
      }
      this.period = 'current_year'
    },
    backendUrl(isExport = false) {
      let url = `${this.$apiURL}/api/statistics`

      if (isExport) {
        url += '/export'
      }

      this.query = `?${qs.stringify({
        filter: this.backendQuery,
      })}`
      this.$emit('query', this.query)

      return `${url}${this.query}`
    },
    initFromQuery() {
      let partner_ids = this.$route.query.partner_ids

      if (partner_ids) {
        partner_ids = partner_ids.split(',').map((id) => parseInt(id, 10))
      }

      this.form = {
        ...this.form,
        ...this.$route.query,
        partner_ids,
      }
    },
    async loadAsyncData() {
      this.$emit('load-data', {})

      let { data } = await this.$axios.get(this.backendUrl())

      this.$emit('load-data', data)
    },
  },
}
</script>

<style lang="scss" scoped>
h3 {
  font-weight: bold;
}

.filter__header {
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.filter__actions {
  display: none;

  @include desktop {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .button {
    margin-left: 1rem;
  }
}

.horizontal {
  .filter__form {
    display: flex;
    flex-direction: column;

    @include desktop {
      flex-direction: row;
    }

    > .field {
      flex: 1;
      padding: 0.75rem;
      margin-bottom: 0;
    }
  }
}

.filter__footer {
  text-align: center;
  margin-top: 2rem;
}
</style>
