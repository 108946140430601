<template>
  <div>
    <div v-if="value" class="file-link">
      <a :href="value.url" target="_blank">
        <div>
          <b-icon icon="file-outline" size="is-medium" type="is-black"></b-icon>
        </div>
        <p>{{ value.name }}.{{ value.type }}</p>
      </a>
      <button
        v-if="deletable && value.id"
        type="button"
        class="button"
        @click="deleteFile"
      >
        <b-icon size="is-small" icon="delete"></b-icon>
      </button>
    </div>
    <span v-else class="file-link">
      <b-icon icon="file-outline" size="is-medium" type="is-black"></b-icon>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    deletable: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    async deleteFile() {
      this.$emit('update:saving', true)

      try {
        await this.$axios({
          method: 'delete',
          url: `/api/media/${this.value.id}`,
        })

        this.$buefy.toast.open({
          duration: 5000,
          message: 'Media supprimé avec succès',
          position: 'is-bottom',
          type: 'is-success',
        })

        this.$router.back()
      } catch (e) {
        if (e.response.status === 422) {
          this.state.errors = e.response.data.errors
        }
      } finally {
        this.$emit('update:saving', false)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
span.file-link {
  .icon {
    opacity: 0.2;
  }
}
.file-link {
  position: relative;
  div {
    display: flex;
    span {
      margin: auto;
    }
  }
  p {
    text-align: center;
  }
}
button {
  position: absolute;
  top: 0;
  right: 0;
}
</style>
