import Vue from 'vue'
import { format as formatDate } from 'date-fns'
import truncate from 'lodash/truncate'

Vue.prototype.$formatDate = (dateValue, withHour = false, options = null) => {
  let date = new Date(dateValue)
  if (!options) {
    options = {
      hour12: false,
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      timeZone: 'Europe/Paris',
    }
    if (withHour) {
      options = {
        ...options,
        hour: '2-digit',
        minute: '2-digit',
        // second: '2-digit',
      }
    }
  }

  let format = date.toLocaleString('fr-FR', options)

  return dateValue ? format : null
  // return dateValue ? formatDate(new Date(dateValue), 'dd/mm/yyyy hh:mm') : '-'
}

Vue.prototype.$formatPercent = (value, decimals = 0) => {
  return new Intl.NumberFormat('fr-FR', {
    style: 'percent',
    minimumFractionDigits: decimals,
  }).format(value || 0)
}

Vue.prototype.$formatNumber = (value) => {
  return new Intl.NumberFormat('fr-FR', {
    minimumFractionDigits: 0,
  }).format(value || 0)
}

Vue.prototype.$formatCurrency = (value) => {
  return new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 0,
  }).format(value || 0)
}

Vue.prototype.$truncate = (value, length = 100) => {
  return truncate(value, { length })
}

Vue.prototype.$keysToCamel = (o) => {
  const toCamel = (s) => {
    return s.replace(/([-_][a-z])/gi, ($1) => {
      return $1.toUpperCase().replace('-', '').replace('_', '')
    })
  }

  let n = {}

  Object.keys(o).forEach((k) => {
    n[toCamel(k)] = o[k]
  })

  return n
}

Vue.prototype.$statusColors = {
  NEW: '#CC74B0',
  NO_ELIGIBLE: '#ea5e34',
  QUOTATION_WAIT: '#ffdd57',
  TO_INSTALL: '#8D85AD',
  QUOTATION_REJECTED: '#065b46',
  INSTALL_WIP: '#3DA0D8',
  INSTALLED: '#FFDA83',
  ACTIVE: '#F37455',
  ABANDONMENT: '#FDAD4A',
  CANCELED: '#3273dc',
}
Vue.prototype.$workflow = {
  NEW: 'NEW',
  NO_ELIGIBLE: 'NO_ELIGIBLE',
  QUOTATION_WAIT: 'QUOTATION_WAIT',
  TO_INSTALL: 'TO_INSTALL',
  QUOTATION_REJECTED: 'QUOTATION_REJECTED',
  INSTALLATION_ABANDON: 'INSTALLATION_ABANDON',
  INSTALL_WIP: 'INSTALL_WIP',
  INSTALLED: 'INSTALLED',
  ACTIVE: 'ACTIVE',
  ABANDONMENT: 'ABANDONMENT',
  CANCELED: 'CANCELED',
}
