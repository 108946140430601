<template>
  <div class="equipment-types">
      <h2>Liste des types d'équipements</h2>

      <div class="is-flex">
          <span class="button ml-auto" @click="onAdd()">Ajouter un type d'équipement</span>
      </div>

      <div class="mt-3">
          <b-table :data="equipmentTypes">
              <b-table-column field="label" label="Type d'équipement" v-slot="props">
                  {{ props.row.label }}
              </b-table-column>
              <b-table-column field="can_add" label="Ajoutable ?" centered v-slot="props">
                  <span v-if="props.row.can_add" class="text-turquoise">Oui</span>
                  <span v-else class="text-red">Non</span>
              </b-table-column>
              <b-table-column field="id" label="Actions" v-slot="props">
                  <div>
                      <b-button
                          title="Editer"
                          icon-left="pencil-outline"
                          @click="onEdit(props.row)"
                          class="mr-2"
                      />
                      <b-button
                          title="Supprimer"
                          icon-left="trash-can-outline"
                          @click="onDelete(props.row)"
                          v-if="!isUsed(props.row.code)"
                      />
                  </div>
              </b-table-column>
              <template #empty>
                  <div class="has-text-centered">Aucun type d'équipement n'a été défini.</div>
              </template>
          </b-table>
      </div>

      <b-modal :active.sync="isModalActive" has-modal-card>
          <div class="modal-card">
              <form class="modal-card-body" @submit.prevent="submit">
                  <h2 style="text-align:center; font-size:1.1rem; font-weight:700; margin-bottom: 1rem;">
                      <span v-if="equipmentType.id==undefined">Ajout d'un type d'équipement</span>
                      <span v-else>Edition du type d'équipement [{{ equipmentType.id }}]</span>
                  </h2>

                  <base-field label="Nom*" name="label" horizontal>
                      <b-input id="label" v-model="equipmentType.label"></b-input>
                  </base-field>

                  <base-field label="Ajoutable ?" name="can_add" horizontal>
                      <b-radio-button
                          v-for="(label, value) in { Oui: 1, Non: 0,}"
                          :key="value"
                          v-model="equipmentType.can_add"
                          :native-value="label"
                      >
                          {{ value }}
                      </b-radio-button>
                  </base-field>

                  <div class="is-flex mt-5">
                      <div class="ml-auto" >
                          <b-button
                              type="is-primary"
                              native-type="submit"
                              :loading="saving"
                              :disabled="equipmentType.label.trim()===''"
                          >
                              <span v-if="equipmentType.id==undefined">Ajouter</span>
                              <span v-else>Enregistrer</span>
                          </b-button>
                          <b-button class="ml-2" @click="isModalActive = false">Annuler</b-button>
                      </div>
                  </div>
              </form>
          </div>
      </b-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Card from "@/components/clients/documents/card.vue";
import qs from "qs";
import store from "@/store";
export default {
    components: {Card},
    data() {
        return {
            isModalActive: false, // modale affichée
            equipmentType: {
                id: null,
                code: '',
                label: '',
                can_add: 1
            }, // type d'équipement édité
            nvlEquipmentType: {
              id: null,
              code: '',
              label: '',
              can_add: 1
            }, // valeurs par défaut pour un nouveau type
            state: {
                errors: {},
            }, // Note : juste pour le fonctionnement de base-field (Field) qui devrait normalement être placé dans base-model-form (ModelForm).
            saving: false, // enregistrement en cours
            usedCodes: [], // liste des codes des types d'équipement utilisés (référencés) et donc non supprimables
        }
    },
    computed: {
        ...mapState({
            equipmentTypes: (state) => state.enums.equipmentTypes,
        }),
    },
    provide() {
        // Note : juste pour le fonctionnement de base-field (Field) qui devrait normalement être placé dans base-model-form (ModelForm).
        return {
            state: this.state,
        }
    },
    async mounted() {
        await this.loadUsedCodes()
    },
    methods: {
        /**
         * Indique si ce code est utilisé (référencé) et donc non supprimable.
         * @param code
         * @return boolean
         */
        isUsed(code) {
            return this.usedCodes.indexOf(code)!==-1
        },
        /**
         * (Re-)Chargement de la liste des codes utilisés.
         * @return {Promise<void>}
         */
        async loadUsedCodes() {
            let { data } = await this.$axios.get('/api/equipment_types/used-codes')
            this.usedCodes = data
        },
        /**
         * Clic sur le bouton d'ajout d'un équipement.
         */
        onAdd() {
            // On clone pour ne pas impacter this.nvlEquipmentType.
            this.equipmentType = JSON.parse(JSON.stringify(this.nvlEquipmentType))
            this.openModal()
        },
        /**
         * Clic sur le bouton de suppression d'un équipement.
         * @param equipmentType
         * @returns {Promise<void>}
         */
        async onDelete(equipmentType) {
            this.$buefy.dialog.confirm({
                title: "Suppression d'un type d'équipement",
                message: `Etes-vous sûr de vouloir supprimer le type d'équipement "${equipmentType.label}" ? Cette action est irréversible !`,
                confirmText: 'Oui, supprimer',
                cancelText: 'Annuler',
                type: 'is-warning',
                hasIcon: true,
                onConfirm: async () => {
                    await this.$axios.delete(`/api/equipment_types/${equipmentType.id}`)
                    this.$buefy.toast.open({
                        message: `Le type d'équipement "${equipmentType.label}" a été supprimé avec succès !`,
                        type: 'is-success',
                        position: 'is-bottom',
                    })
                    await this.reload()
                },
            })
        },
        /**
         * Clic sur le bouton d'édition d'un équipement.
         * @param equipmentType
         * @returns {Promise<void>}
         */
        async onEdit(equipmentType) {
            // On clone pour ne pas impacter equipmentType (notamment si on annule après avoir modifié des champs).
            this.equipmentType = JSON.parse(JSON.stringify(equipmentType))
            this.equipmentType.can_add = this.equipmentType.can_add ? 1 : 0
            this.openModal()
        },
        /**
         * Ouverture de la modale.
         */
        openModal() {
            //console.log('this.equipmentType', this.equipmentType)
            this.isModalActive = true
            this.saving = false
        },
        /**
         * Rechargement de la liste.
         * @returns {Promise<void>}
         */
        async reload() {
            // Recharger le référentiel.
            await store.dispatch('loadEnums')
            await this.loadUsedCodes()
        },
        /**
         * Soumission du formulaire.
         * @returns {Promise<void>}
         */
        async submit() {
            // URL.
            let url = '/api/equipment_types'
            if ( this.equipmentType.id!=undefined ) {
                // Edition.
                url += `/${this.equipmentType.id}`
            }

            // Envoi au serveur.
            let errors = false
            this.saving = true
            try {
                await this.$axios({
                    method: this.equipmentType.id==undefined ? 'post' : 'patch',
                    url: url,
                    data:{label: this.equipmentType.label, can_add:this.equipmentType.can_add}
                })
                errors = false
                this.$buefy.toast.open({
                    message: 'Les données ont été enregistrées avec succès !',
                    type: 'is-success',
                    position: 'is-bottom',
                })
            } catch (error) {
                errors = true
                this.$buefy.toast.open({
                    message: 'Une erreur s\'est produite lors de l\'envoi au serveur !',
                    type: 'is-failed',
                    position: 'is-bottom',
                })
            }
            await this.reload()
            this.saving = false

            // Fermeture de la modale.
            if ( !errors ) {
                this.$emit('refresh')
                this.isModalActive = false
            }
        },
    }
}
</script>

<style lang="scss" scoped>
    .equipment-types {
        max-width: 700px;
    }
</style>
