<template>
  <div class="sav-item-card">
    <div style="display:flex; justify-content:end; gap:10px">
      <b-button style="position:static !important"
        v-if="this.user.is_super_admin || this.user.is_admin || this.user.is_user"
        class="card-edit"
        icon-left="pencil-outline"
        @click="edition = !edition"
      ></b-button>
      <base-delete-button v-if="this.user.is_super_admin || this.user.is_admin || this.user.is_user"
          :id="savItem.id"
          model="sav-item"
          confirm-title="Supprimer un SAV"
          confirm-message="Etes-vous sûr de vouloir supprimer ce SAV ? Cette action est irréversible !"
          success-message="SAV supprimé avec succès !"
          @deleted="reload()"
        />
    </div>
    <div v-if="edition">
      <Form
        class="mt-6"
        :model="savItem"
        :edition="edition"
        @validate="edition = false"
      />
    </div>
    <div v-else>
      <div>
        Le {{ $formatDate(savItem.created_at, 'dd/MM/yyyy HH:mm') }}
        <span v-if="savItem.commercial">
          par {{ savItem.commercial.name }}</span
        >
        : SAV niveau {{ savItem.level }}
      </div>
      <div class="mt-2">
        {{ savItem.comment }}
      </div>
      <div v-if="savItem.level >= 2 && savItem.level < 3" class="mt-2">
        Intervention :
        {{ $formatDate(savItem.intervention_at, 'dd/MM/yyyy HH:mm') }}
      </div>
      <div v-if="savItem.level >= 3" class="mt-2">
        <div>R1 : {{ $formatDate(savItem.r1_at, 'dd/MM/yyyy HH:mm') }}</div>
        <div>R2 : {{ $formatDate(savItem.r2_at, 'dd/MM/yyyy HH:mm') }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Form from './form.vue'
export default {
  props: {
    savItem: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
  data() {
    return {
      edition: false,
    }
  },
  components: { Form },
  methods: {
    reload() {
      this.$emit('refresh')
    },
   }
}
</script>

<style lang="scss" scoped>
.sav-item-card {
  border-bottom: 1px solid gray;
  padding-bottom: 1rem;
  padding-top: 1rem;
  position: relative;
  .card-edit {
    position: absolute;
    top: 0.75rem;
    right: 0;
  }
}
</style>
