export default {
  props: {
    id: String,
    media: {
      type: Object,
      default: () => {},
    },
    expanded: Boolean,
    multiple: Boolean,
    dragDrop: Boolean,
  },
  data: () => ({
    file: null,
    deleted: false,
  }),
  watch: {
    file(newVal) {
      this.$emit('input', newVal)
    },
    deleted() {
      if (!this.file) {
        this.$emit('input', false)
      }
    },
  },
}
