import Vue from 'vue'

const files = require.context('@/components/base', true, /\.vue$/i)
files
  .keys()
  .map((key) =>
    Vue.component(
      `Base${key.split('/').pop().split('.')[0]}`,
      files(key).default
    )
  )
