<template>
  <div>
    <fieldset class="mb-6">
      <legend>Infos utilisateur</legend>
      <base-field
        horizontal
        :label="`IDGRC${user.is_bringer ? '*' : ''}`"
        name="user_id_grc"
      >
        <b-input v-model="form.user_id_grc" />
      </base-field>
      <base-field horizontal label="Civilité*" name="user_civility">
        <b-radio-button
          v-for="(label, i) in ['Mr', 'Mme']"
          :key="i"
          v-model="form.user_civility"
          :native-value="label"
          type="is-success is-light is-outlined"
        >
          {{ label }}
        </b-radio-button>
      </base-field>
      <base-field horizontal label="Nom*" name="user_last_name">
        <b-input v-model="form.user_last_name" />
      </base-field>
      <base-field horizontal label="Prénom*" name="user_first_name">
        <b-input v-model="form.user_first_name" />
      </base-field>

      <base-field horizontal label="Adresse 1*" name="user_address_line_1">
        <b-input v-model="form.user_address_line_1" />
      </base-field>
      <base-field horizontal label="Adresse 2" name="user_address_line_2">
        <b-input v-model="form.user_address_line_2" />
      </base-field>
      <base-field horizontal label="CP Ville*" name="user_postal_code">
        <Place v-model="userPlace" />
      </base-field>

      <base-field horizontal label="Téléphone*" name="user_phone">
        <b-input v-model="form.user_phone" v-mask="'0# ## ## ## ##'" />
      </base-field>
      <base-field horizontal label="Mobile" name="user_mobile">
        <b-input v-model="form.user_mobile" v-mask="'0# ## ## ## ##'" />
      </base-field>
      <base-field horizontal label="Date de naissance*" name="user_birth_date">
        <base-datepicker
          id="birth-date"
          icon="calendar-today"
          trap-focus
          v-model="form.user_birth_date"
          position="is-bottom-right"
          :force-mobile="true"
        ></base-datepicker>
      </base-field>
      <base-field
        horizontal
        label="Est souscripteur ?"
        name="user_is_subscriber"
      >
        <b-switch
          id="user-is-subscriber"
          v-model="form.user_is_subscriber"
        ></b-switch>
      </base-field>
    </fieldset>

    <fieldset class="mb-6" v-if="!form.user_is_subscriber">
      <legend>Infos souscripteur</legend>
      <base-field horizontal label="Civilité*" name="subscriber_civility">
        <b-radio-button
          v-for="(label, i) in ['Mr', 'Mme']"
          :key="i"
          v-model="form.subscriber_civility"
          :native-value="label"
          type="is-success is-light is-outlined"
        >
          {{ label }}
        </b-radio-button>
      </base-field>
      <base-field horizontal label="Nom*" name="subscriber_last_name">
        <b-input v-model="form.subscriber_last_name" />
      </base-field>
      <base-field horizontal label="Prénom*" name="subscriber_first_name">
        <b-input v-model="form.subscriber_first_name" />
      </base-field>

      <base-field
        horizontal
        label="Adresse 1*"
        name="subscriber_address_line_1"
      >
        <b-input v-model="form.subscriber_address_line_1" />
      </base-field>
      <base-field horizontal label="Adresse 2" name="subscriber_address_line_2">
        <b-input v-model="form.subscriber_address_line_2" />
      </base-field>
      <base-field horizontal label="CP Ville*" name="subscriber_postal_code">
        <Place v-model="subscriberPlace" />
      </base-field>

      <base-field horizontal label="Téléphone*" name="subscriber_phone">
        <b-input v-model="form.subscriber_phone" />
      </base-field>
      <base-field horizontal label="Email*" name="subscriber_email">
        <b-input v-model="form.subscriber_email" type="email" />
      </base-field>
    </fieldset>

    <fieldset class="mb-6" v-if="hasSecondUser">
      <legend>Second utilisateur</legend>
      <base-field horizontal label="Civilité*" name="second_user_civility">
        <b-radio-button
          v-for="(label, i) in ['Mr', 'Mme']"
          :key="i"
          v-model="form.second_user_civility"
          :native-value="label"
          type="is-success is-light is-outlined"
        >
          {{ label }}
        </b-radio-button>
      </base-field>
      <base-field horizontal label="Nom*" name="second_user_last_name">
        <b-input v-model="form.second_user_last_name" />
      </base-field>
      <base-field horizontal label="Prénom*" name="second_user_first_name">
        <b-input v-model="form.second_user_first_name" />
      </base-field>

      <base-field horizontal label="Téléphone*" name="second_user_phone">
        <b-input v-model="form.second_user_phone" v-mask="'0# ## ## ## ##'" />
      </base-field>
      <base-field horizontal label="Mobile" name="second_user_mobile">
        <b-input v-model="form.second_user_mobile" v-mask="'0# ## ## ## ##'" />
      </base-field>
      <base-field
        horizontal
        label="Date de naissance*"
        name="second_user_birth_date"
      >
        <base-datepicker
          id="birth-date"
          icon="calendar-today"
          trap-focus
          v-model="form.second_user_birth_date"
          position="is-bottom-right"
          :force-mobile="true"
        ></base-datepicker>
      </base-field>
    </fieldset>

    <fieldset class="mb-6">
      <legend>Coordonnées bancaires</legend>
      <base-field horizontal label="Nom de la banque*" name="bank_name">
        <b-input v-model="form.bank_name" />
      </base-field>
      <base-field horizontal label="BIC*" name="bank_bic">
        <b-input v-model="form.bank_bic" />
      </base-field>
      <base-field horizontal label="IBAN*" name="bank_iban">
        <b-input v-model="form.bank_iban" />
      </base-field>
    </fieldset>
  </div>
</template>

<script>
import Place from '../base/inputs/Place.vue'
import { mapState } from 'vuex'

export default {
  components: {
    Place,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    hasSecondUser: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    form: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    userPlace: {
      get() {
        if (!this.form.user_postal_code || !this.form.user_city) return ''
        return `${this.form.user_postal_code} - ${this.form.user_city}`
      },
      set(value) {
        let data = value.split(' - ')
        let place = {
          user_postal_code: data[0],
          user_city: data[1],
        }

        this.form = { ...this.form, ...place }
      },
    },
    subscriberPlace: {
      get() {
        if (!this.form.subscriber_postal_code || !this.form.subscriber_city)
          return ''
        return `${this.form.subscriber_postal_code} - ${this.form.subscriber_city}`
      },
      set(value) {
        let data = value.split(' - ')
        let place = {
          subscriber_postal_code: data[0],
          subscriber_city: data[1],
        }

        this.form = { ...this.form, ...place }
      },
    },
  },
}
</script>

<style lang="scss" scoped>
fieldset {
  margin-bottom: 1.5rem;
}
legend {
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 1rem;
}
</style>
