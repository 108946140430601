<template>
  <div class="container">
    <h1>{{ $route.meta.title }}</h1>
    <user-form></user-form>
  </div>
</template>

<script>
import UserForm from './Form'

export default {
  components: { UserForm },
}
</script>
