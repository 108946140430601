<template>
  <div class="error">
    <div class="icon">
      <b-icon icon="cancel" size="is-large"></b-icon>
    </div>
    <h1>
      {{ message }}
    </h1>
  </div>
</template>

<script>
export default {
  props: {
    status: {
      type: Number,
      default: 404,
    },
    message: {
      type: String,
      default: 'Page inexistante',
    },
  },
}
</script>

<style lang="scss" scoped>
.error {
  text-align: center;
  margin-top: 3rem;

  .icon {
    margin-bottom: 1rem;
  }
}
</style>
